.payment_methods_icons {
  height: 25px;
  width: 25px;
  object-fit: contain;
}
.payment_methods_icons_master {
  height: 34px;
  width: 45px;
  object-fit: contain;
  filter: grayscale(100%);
}
.payment_methods_icons_apple {
  height: 19px;
  width: 45px;
  object-fit: contain;
  filter: grayscale(100%);
}
.check_box_item {
  cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    transition: all .2s ease-in-out;
}

.check_box_item_new {
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  gap: 10px;
  transition: all .2s ease-in-out;
  flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
}

.check_box_item label {
  font-size: 20px;
}
.input[type="checkbox" i] {
  appearance: unset;
}
.check_input {
  -webkit-appearance: none;
  background-color: #fdfdfd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 12px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-shadow: 2px 0px 5px #88888836;
  transition: all .2s ease-in-out;
}
.check_input:checked {
  background-color: #ee4236;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
.check_input:active,
.check_input:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width:1150px){
  .check_input:hover { 
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0px 0px 10px 1px #30302f;
  }
}

.check_box_item_wraper {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
}

.payment_methods_body,
.reciept {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 2px 0px 5px #88888836;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}
.checkOut_left_side,
.checkOut_right_side {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.checkOut_left_side {
  border-right: 1px solid gray;
}
.add_new_card {
  border: none;
  border-radius: 30px;
  padding: 10px;
  background-color: #ee423642;
  color: #ee4236;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 25px;
  margin-right: 25px;
  transition: all .2s ease-in-out;
}

@media screen and (min-width:1150px){
  .add_new_card:hover{
      background-color: white;
      color: #ee4236;
      transform: scale(1.1); 
      box-shadow: 0px 0px 20px 1px #30302f;
  }
  }

.barCode {
  height: 50px;
  width: 80%;
  object-fit: cover;
}
.reciept_box_title {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reciept_box_Subtitle {
  padding: 10px;
  border-bottom: 1px solid gray;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.reciept_box_body {
  padding: 10px;
  border-bottom: 1px solid gray;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.reciept_element {
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: right;
}
.reciept_total_wraper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.reciept_total_text {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px dashed gray;
}
.reciept_total_text label {
  font-weight: bold;
  font-size: 20px;
}
.reciept_barCode_wraper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reciept_ball {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: transparent;
  background-color: #fdfdfd;
}
.leftBall {
  position: absolute;
  right: -60px;
  bottom: -15px;
  box-shadow: inset 2.5px 0px 2.5px 0px #88888813;
}
.rightBall {
  position: absolute;
  left: -60px;
  bottom: -15px;
  box-shadow: inset -2.5px 0px 2.5px 0px #88888813;
}
.checkOut_total {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px;
  align-items: flex-start;
  justify-content: space-between;
}
.reciept {
  align-self: center;
  position: relative;
  justify-content: flex-start;
}
.ERC_logo_for_reciept {
  position: absolute;
  height: 38px;
  width: 29px;
  object-fit: cover;
  right: 12px;
  top: 15px;
}
tr:first-child {
  border-bottom: 1px solid gray;
}
tr:last-child {
  border-bottom: 1px dashed gray;
}
tr:nth-last-child(2) {
  border-bottom: 1px solid gray;
}
.check_out_page {
  min-height: 800px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.btn_for_mob_only {
  width: 90%;
  border: none;
  background-color: #ee4236;
  color: white;
  align-self: center;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  display: none;
  transition: all .2s ease-in-out;
}

@media screen and (min-width:1150px){
  .btn_for_mob_only:hover{
      background-color: white;
      color: #ee4236;
      transform: scale(1.1); 
      box-shadow: 0px 0px 20px 1px #30302f;
  }
  }
@media (max-width: 1150px) {
  .title_to_be_remove {
    display: none;
  }
  .checkOut_total {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .payment_methods_body {
    align-self: center;
  }
  .checkOut_left_side {
    border: none;
  }
  .btn_for_mob_only {
    display: flex;
  }
  .checkOut_left_side,
  .checkOut_right_side {
    padding: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
    gap: 5px;
    border: none !important;
  }
  .checkOut_total {
    padding: 0px;
  }
  .payment_methods_body,
  .reciept {
    width: 99%;
  }
}

.check_radio_selected {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
}
.check_radio_unselected {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(113, 112, 112);
}
@media (max-width: 1150px) {
  .payment_methods_body,
  .reciept {
    width: 90%;
  }
  .reciept_ball {
    display: none;
  }
}
@media (max-width: 500px) {
  .payment_methods_body,
  .reciept {
    width: 90%;
  }
}