/* @media (max-width: 1150px) {
    #home {
        margin-top: 60px;
    }
} */
.cssmarquee {
    min-height: 44px;
    overflow: hidden;
    padding: 5px;
    width: 190px;
    }
.cssmarquee_arab {
    min-height: 44px;
    overflow: hidden;
    padding: 5px;
    width: 190px;
}
.cssmarquee, .cssmarquee_arab {
    white-space: nowrap;
    margin: 0;
    text-align: center;
}

 @keyframes cssmarquee {
    0% {
    transform: translateX(100%);
    }
    100% {
    transform: translateX(-100%);
    }
}
@keyframes cssmarquee_arab {
    0% {
    transform: translateX(0%);
    }
    100% {
    transform: translateX(90%);
    }
}
    

    @media (max-width: 1111px) {
        .cssmarquee, .cssmarquee_arab {
            min-height: 33px;
            width: 130px;
        }

    }
    