.project_img {
    height: 200px;
    width: 300px;
    border-radius: 10px;
    object-fit: fill;
    object-position: center;
    filter: brightness(90%);
    border-radius: 5px;
    z-index: 1;
}
.projects_com {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    /* margin: 20px; */
    z-index: 100;
}
.projects_com_box{
    overflow: hidden !important;
    border-radius: 15px !important;
    transition: all .2s ease-in-out;
    z-index: 2;
}

@media screen and (min-width:900px){
    .projects_com_box:hover { 
        cursor: pointer;
        transform: scale(1.1); 
        box-shadow: 0px 0px 20px 1px #313131;
    }
}
.projects_com_box img {
    overflow: hidden !important;
    transform-origin: 55% 55%;
    transition: transform 5s ease;
    border-radius: 5px;
  }

@media screen and (min-width:900px){
    .project_img:hover {
    transform: scale(1.5);
    border-radius: 5px;
  }
}

@media (max-width: 975px) {
    .project_img {
        height: 150px !important;
        width: 200px !important;
        border-radius: 0px !important;
        object-fit: cover;
    }
}
@media (max-width: 470px) {
    .project_img {
        height: 90px !important;
        width: 120px !important;
        border-radius: 0px !important;
        object-fit: cover;
    }
}
@media (max-width: 330px) {
    .project_img {
        height: 75px !important;
        width: 100px !important;
        border-radius: 0px !important;
        object-fit: cover;
    }
}
@media (max-width: 250px) {
    .noWidth {
        gap: 0px;
    }
}

@media (max-width: 600px) {
    .label_to_beSmall2 {
        font-size:12px;
    }
}

.label_to_beSmall2{
    margin-top: 20px;
}