.welcome_modal_slideOne {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.back_skip_btns_wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.skip_btn {
    background-color: transparent;
    border: none;
    color: gray;
}
.modalSlideImg {
    width: 100%;
    object-fit: contain;

}
.moveOn_btn {
    padding: 10px 20px 10px 20px;
    background-color: #ee4236;
    border-radius: 9px;
    border: none;
    color: white;
    width: 90%;
}
.bottom_of_the_modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.Lang_one_btn_img {
    height: 50px;
    width: 70px;
}
.Lang_one_btn {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid black;
    padding: 5px;
}
.chooseLang_btn_wraper {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
}
.someThingLike_Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
    margin-bottom: 74px;
}
.pagination_dots {
    min-height: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 7px;
}
/* .donation_pop_up_body_forWelcome .modal-content .modal-body {
    min-height: 704px;
} */
@media (max-width: 1150px) {
    .donation_pop_up_body_forWelcome .modal-dialog .modal-content {
        top: 0px;
    }
    .Lang_one_btn_img {
        height: 50px;
        width: 70px;
    }
    .welcome_modal_slideOne {
        gap: 20px;
    }
    .someThingLike_Form {
        gap: 10px;
    }
}
@media (max-width: 1920px) {
    .Lang_one_btn_img {
        height: 50px;
        width: 70px;
    }
}