.course_new {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../../assets/course_bg.svg");
  background-position: center;
  background-size: cover;
  .child_main {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 40vh;
    .main_bg {
      width: 100%;
      min-height: 300px;
    }
    .child_title {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 18px;
        background-color: #413c3c;
        color: #ffffff;
        padding: 5px 50px;
        border-radius: 9px;
      }
      h1 {
        color: #000000;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }
}

//carts
.filter {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 40px;
  padding-top: 40px;
  .child_filter {
    border: 1px solid #ACABAB;
    border-radius: 15px;
    display: flex;
    gap: 12px;
    padding: 10px 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    p {
      margin-bottom: 0px;
      color: #ACABAB;
      font-size: 18px;
    }
  }
  img{
    position: absolute;
    /* top: 50%; */
    right: 49px;
    transform: translateY(29%);
  }
}
.main_carts {
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 30px;
  padding: 30px 40px 70px 40px;
  justify-content: center;
  .child_carts {
    display: flex;
    flex-direction: column;
    background-color: #fbfbfb;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 20px;
    cursor: pointer;
    .image_main {
      position: relative;
      .cart_image {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 17px;
        border-top-right-radius: 17px;
      }
      .amound {
        position: absolute;
        bottom: 0;
        background-color: #141313;
        opacity: 0.9;
        width: 100%;
        p {
          color: #ffffff;
          margin-bottom: 1px;
          padding: 8px 20px;
          font-size: 18px;
          font-weight: 300;
        }
      }
    }
    .cart_body {
      padding: 20px;
      .icons_details {
        padding-top: 10px;
        padding-bottom: 20px;
        display: flex;
        justify-content: start;
        gap: 30px;
        .child_details {
          display: flex;
          gap: 5px;
          align-items: center;
          small {
            font-size: 15px;
            color: #000000;
          }
        }
      }
      .title_descrie {
        color: #817b7b;
        text-align: left;
        h3 {
          font-weight: 200;
          font-size: 20px;
          color: #000;
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Number of lines to show */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          white-space: normal;
        }
      }
      .action_btn {
        text-align: center;
        display: flex;
        justify-content: center;
        p {
          background-color: #b11818;
          color: #ffffff;
          padding: 7px 35px;
          border-radius: 13px;
          margin-bottom: 1px;
          font-weight: 600;
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .main_carts {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 30px 20px 20px 20px;
    .child_carts {
      margin-top: 10px;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
  .main_carts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .main_carts {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1800px) and (max-width: 3000px) {
  .main_carts {
    grid-template-columns: repeat(6, 1fr);
  }
}
