.requestsLobby_navi_item {
    background-color: transparent;
    border: none;
    padding: 15px 0px 15px 0px;
    width: 100%;
    color: #ee4236;
}
.requestsLobby_navi_item:last-child {
    border: none;
}
.requestsLobby_navi {
    width: 100%;
    display: flex;
}
.requestsLobby {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
@media (max-width: 900px) {
    .requestsLobby {
        padding: 0px;
        gap: 10px;
    }
}