.dateItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.switch_donation_comp {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.switch_donation_comp:nth-child(even) {
    background-color: #d3d3d333
}
@media (max-width: 900px) {
    .dateItem {
        font-size: 11px;
    }
}