.local_aid_btn {
  background-color: white;
  border: none;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 900px) {
  .local_aid_btn:hover {
    /* background-color: white; */
    color: #ee4236 !important;
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 3px #30302f;
  }
}
.local_aid_btn_img {
  height: 150px;
  width: 150px;
  object-fit: fill;
}
.local_aid_btn_wraper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.local_aid_page {
  min-height: 650px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.localAid_page_wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
@media (max-width: 900px) {
  .local_aid_btn_img {
    height: 120px;
    width: 120px;
    object-fit: fill;
  }
  .localAid_page_wraper {
    padding: 0px;
  }
}

.local_aid_container {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  background-color: #fff;
}
.image_section img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 25px;
  border-radius: 30px;
}
.see_all_btn {
  display: flex;
  gap: 10px;
  align-items: baseline;
  margin-top: 30px;
  float: right;
  color: gray;
}
.local_aid_profile_container,
.local_aid_relative_container,
.local_aid_request_container {
  border: 1px solid #e2e2e2;
  padding-top: 50px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 580px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.req_btn {
  display: flex;
  justify-content: center;
  gap: 80px;
  align-items: center;
}
.local_aid_profile_container input,
.local_aid_relative_container input,
.local_aid_request_container input {
  margin-bottom: 10px;
}

tr:last-child {
  border-bottom: 1px solid rgb(205, 205, 205) !important;
}

tr:first-child {
  border-bottom: 1px solid gray;
}

.local_aid_profile_container:hover,
.local_aid_relative_container:hover,
.local_aid_request_container:hover {
  border-color: #ee4236;
  transition: 0.5s;
}
.table_contai_main {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.table_contai {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid #9a9a9a;
  margin-bottom: 10px;
}
.table_contai span {
  border-right: 1px solid gray;
  margin-right: -18px;
  width: 50%;
  text-align: center;
}
.table_contai span:last-child {
  border: none;
}
