#humanitarian {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#human {
    width: 100%;
}
#human .carousel-indicators {
    margin-bottom: -45px !important;
    background-color: #fdfdfd;
    margin: 0px;
    align-items: center;
}
#human  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
#human  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
@media (max-width:1600px) {
    #human .carousel-indicators{
        margin-bottom:0px !important;
    }
}

#human .carousel-indicators [data-bs-target] {
    background-color: rgb(99, 99, 99);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 20px 5px 0px 0px
}
#human .carousel-caption {
    padding-bottom: 3rem;
}
#human .carousel-indicators .active {
    background-color: #ee4236;
    height: 15px;
    width: 15px;
}

@media screen and (max-width:1150px){
    #human .carousel-control-next,
    #human .carousel-control-prev {
        background: none !important;
        width: 5%;
        margin: 2%;
        transition: all .4s ease-in-out;
} 
} 

@media screen and (min-width:1150px){
    #human .carousel-control-next,
    #human .carousel-control-prev {
        background: none !important;
        width: 2%;
        margin: 0.5%;
        transition: all .4s ease-in-out;
} 
    #human .carousel-control-prev, #human .carousel-control-prev-icon {
        background-image: url("../../../../assets/carousel_prev.png"); 
}
   
   #human .carousel-control-next, #human .carousel-control-next-icon {
       background-image: url("../../../../assets/carousel_next.png");
}
    #human .carousel-control-next:hover, 
    #human .carousel-control-prev:hover { 
        border-radius: 50px;
        cursor: pointer;
        transform: scale(1.25); 
  } 
} 



@media (max-width: 1150px) {
    #human {
        padding: 5px;
    }
    #humanitarian {
        padding: 0px;
        gap: 10px;
    }
    .all_section_title_hum {
        font-size: 17px !important;
        padding: 0px 10px 0px 10px !important;
    }
    .btn_human {
        margin-bottom: 45px;
        padding-bottom: 10px;
    }
}

#human .swiper-wrapper {
    margin-left: 0px !important;
}
#human .swiper-pagination {
    background-color: #fdfdfd;
    bottom: 0px;
}
@media (max-width: 900px) {
    #banner .swiper-button-prev,  #banner .swiper-button-next, #human .swiper-button-prev,  #human .swiper-button-next{
      display: block !important;
    }
  }


.all_section_title_hum {
    font-weight: bolder;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 0.05rem #00000087;
}