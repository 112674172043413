.profile_btn {
    background-color: transparent;
    border: none;
    width: 100%;
    border-radius: 10px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease-in-out;
}
.profile_btn_content {
    min-height: 61px;
    color: gray;
    font-size: 22px;
}