.relatives_display_for_event_details {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 85vh;
}
.row_for_event_details {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.rollBtn {
    width: 60%;
    padding: 10px 20px 10px 20px;
    background-color: #ee4236;
    color: white;
    border: none;
    border-radius: 15px;
}
.rollBtn:disabled {
    filter: grayscale(100%);
}
@media (max-width: 550px) {

.relatives_display_for_event_details {
      padding: 0px;
}
}