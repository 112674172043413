.event-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
    margin-top: 15px;
    
}
.event-card > div {
    font-weight: bold;
}
.event-card > div:last-child
{
    padding-top: 20px;
}
.weekday_label {
    font-size:1rem;
    font-family: 'english_font_bold';
}
.day_label {
    font-size: 3rem;
    font-family: 'english_font_bold';
    font-weight: 900;

}
.month_label {
    font-size: 1rem;
    font-family: 'english_font_bold';

}
.event_label {
    font-size: 1rem;
    font-family: 'english_font_bold';

}

.left-red-thing {
    position: absolute;
    color: red;
    background-color: red;
    height: 30px;
    width: 10px;
    top: -13px;
    left: 15%;
    border-radius: 5px;
}

.right-red-thing {
    position: absolute;
    color: red;
    background-color: red;
    height: 30px;
    width: 10px;
    top: -13px;
    right: 15%;
    border-radius: 5px;


}