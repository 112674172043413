/* ===================Shared===================   */
.report-user-nationality,
.report-user-DOB,
.report-user-adoption-date,
.report-date,
.report-date-value,
.report-date-number {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.fadedOutReport {
  color: gray;
}

.sppdf-body {
  font-size: 1rem;
  max-width: 80vw;
  margin: 0 auto;
}

@media (max-width: 1150px) {
  .sppdf-body {
    font-size: 0.8rem;
  }
}
@media (max-width: 375px) {
  .sppdf-body {
    font-size: 0.5rem;
  }
}

/* ===================Report header===================   */

.report-header {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.report-header-details {
  display: flex;
  flex-direction: row;
  min-height: 200px;
}
.report-user-details {
  width: 50%;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.report-user-data {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.reportHeaderStyle {
  font-size: 1rem;
}

.report-date {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

/* ===================Report content===================   */

.report-content {
}

.report-content-intro {
  margin-bottom: 30px;
}

.report-content-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  gap: 10px;
}

.report-content-info-left {
  width: 50%;
}

.report-family-details {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.report-content-info-right {
  width: 50%;
}

/* ===================Report footer===================   */

.report-content-more-info {
  margin-bottom: 30px;
}
/* #divToPrint { 
  height: 100vw;
} */
