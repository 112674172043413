.sendGiftInputs {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  row-gap: 20px;
}
.width {
  width: 100%;
}
.width2 {
  width: 0%;
}
.sendGiftBtn {
  background-color: #ee4236;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  padding: 0 50px;
  cursor: pointer;
  margin-top: 30px;
  width: 100%;
}
@media (max-width: 550px) {

  .sendGiftInputs {
    width: 100%;
    padding: 10px;
  }
}