.share_create_success {
    display: flex;
    flex-direction: column;
    /* align-content: center; */
    /* justify-content: center; */
    grid-gap: 30px;
    gap: 30px;
    height: 500px;
    width: 100%;
    align-items: center;

}
.share_create_success img {
    height: 200px;
    /* width: 200px; */
    min-height: 0px;
    /* min-width: 0px; */
}
.share_create_success button {
    width: 80%;
}

.salary_choosen_project_btn_share {
    border-radius: 10px;
    border: none;
    background-color: white;
    box-shadow: 1px 0px 8px #8888884f;
    font-size: 1rem;
    transition: all .2s ease-in-out;
    overflow: hidden;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.44) 0px 0px 10px 1px;

}
.salary_choosen_project_btn_share:hover { 
    cursor: pointer;
    transform: scale(1.05); 
    box-shadow: 0px 0px 5px 2px #00000062;
}