.grace_info {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 14px;
  border-radius: 10px;
  height: 250px;
  transition: all .3s ease-in-out;
}
.ourLoactions, .media, .news {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .3s ease-in-out;
}
.grace_info_grid {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns:33% 33% 33%;
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  transition: all .3s ease-in-out;
}
@media screen and (max-width: 500px) {
  .grace_info_grid {
    grid-template-columns: 45% 45%;
  }
}
@media screen and (max-width: 300px) {
  .grace_info {
    height: 150px;
  }
}
@media screen and (max-width: 200px) {
  .grace_info_grid {
    grid-template-columns: 100%;
  }
}
@media screen and (min-width:900px){
  .grace_info:hover{
      /* background-color: white; */
      color: #ee4236 !important;
      /* transform: scale(1.05);  */
      box-shadow: 0px 0px 5px 5px #4b4b4b;
      filter: none !important;
  }
}