.social-media {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  
}

.social-media-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 30px;
  transition: all .3s ease-in-out;
}
@media screen and (min-width:1150px){
  .social-media-btn:hover { 
      transform: scale(1.2);
      color: white !important;
      box-shadow: 0px 0px 15px 1px #fdfdfd;
}
}

.social-media-btn:nth-child(1) {
  background-color: #25d366;
}

.social-media-btn:nth-child(2) {
  background: #1da1f2;
}

.social-media-btn:nth-child(3) {
  background: #1877f2;
}

.social-media-btn:nth-child(6) {
  background: #0a66c2;
}

.link-container {
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: flex-start;
  justify-content: center;
}

.link {
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 100%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.copy-btn {
  border: none;
  background-color: black;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: white;
  text-transform: capitalize;
  position: absolute;
  font-size: 16px;
}
