.mission_page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    min-height: 700px;
}
.mission_content {
    padding: 20px;
}