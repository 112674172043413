.requestItem {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 2px 0px 5px #88888836;
}
 .request_full_details {
     width: 100%;
     padding: 0px 10px 0px 10px;
     display: flex;
     align-items: center;
     justify-content: space-evenly;
 }
 .record_for_requ_item {
     display: flex;
     align-items: center;
     gap: 10px;
     justify-content: flex-start;
 }
 .editSubmit_btn {
     border-radius: 5px;
     border: none;
     min-width: 100px;
     min-height: 40px;
     align-self: flex-end;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: black;
     color: #fff;
 }
 .edit_returned_display {
     width: 70%;
     align-self: center;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 5px;
 }
 .returned_text_area {
     width: 100%;
     box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.582);
     border: none;
     border-radius: 10px;
 }
 .upload_button {
     border: none;
     background-color: #ee4236;
     color: #fff;
     border-radius: 5px;
     height: 42px;
     width: 80px;
 }
 .upload_agreement_button {
    border: none;
    background-color: #ee4236;
    color: #fff;
    border-radius: 5px;
    height: 62px;
    width: 200px;
}
 @media (max-width: 700px) {
    .requestItem label {
        font-size: 12px;
    }
    .requestItem {
        padding: 10px;
    }
    .request_full_details {
        padding: 0px;
    }
    .request_full_details {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .toBeShowen {
        display: inline-block !important;
    }
    .edit_returned_display {
        width: 100%;
    }
    .upload_button {
        align-self: center;
        height: 30px;
    }
    .editSubmit_btn {
        min-height: 30px;
    }
    .returned_text_area {
        font-size: 13px;
    }
}
