.events_join {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 50px;
  gap: 30px;
  .events_left {
    background-color: #f4f4f4;
    flex-basis: 70%;
    padding: 60px;
    border-radius: 13px;
    .title_section {
      color: #000000;
      h1 {
        line-height: 50px;
      }
      p {
        font-size: 17px;
      }
    }
    .image_section {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .join_right {
    background-color: #f4f4f4;
    flex-basis: 30%;
    padding: 40px;
    border-radius: 13px;
    align-items: center;
    h5 {
      font-size: 25px;
    }
    .dates_join {
      display: flex;
      justify-content: start;
      gap: 50px;
      padding-top: 30px;
      .child_join {
        display: flex;
        align-items: center;
        gap: 5px;
        p{
            margin-bottom: 1px;
            font-size: 18px;
        }
      }
    }
    .text_join{
        padding-top: 20px;
        p{
            font-size: 18px;
        }
    }
    .file_join{
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        label {
            padding: 18px 32px;
            text-align: left;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            margin: 4px 2px;
            cursor: pointer;
            background-color: white;
            color: black;
          }
    }
    .btn_events {
      display: flex;
      justify-content: center;
      align-items: end;
      padding-top: 100px;
      p {
        padding: 10px 30px;
        background-color: #b11818;
        border-radius: 8px;
        color: #ffff;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .events_join {
    flex-direction: column;
    padding: 25px 20px;
    .events_left {
      padding: 30px;
    }
    .events_right {
      padding: 30px;
      gap: 30px;
    }
    .image_section {
      flex-direction: column;
    }
  }
}
