.media {
  min-height: 90vh;
}

.Media_wraper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  gap: 10px;
}

.paymentBody {
  width: 60vw;
  /* margin: 0 auto; */
  margin-top: 50px;
}

.addPaymentMethodForm input {
  border: none;
  border-bottom: 1px solid #c1c1c1;
  width: 100%;
  background: transparent;
}

.addPaymentMethodForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.addPaymentCardBtn {
  height: 40px;
  width: 80%;
  background: #ee4236;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.rccs__card--unknown>div {
  background: black !important;
}

.rccs__card--front,
.rccs__card--back {
  background: black !important;
}
@media (max-width: 600px) {
  .profile_btn_content {
    font-size: 15px;
  }
}
@media (max-width: 300px) {
  .profile_btn_content {
    font-size: 11px;
  }
}
@media (max-width: 900px) {
  .Media_wraper {
    padding: 10px;
  }
}