#all_sukuk_com {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}
.sukuk_item_board_all {
    width: 100%;
    /* height: 100%; */
    gap: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 7;
}
.btn-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.like_btn_for_sukuk_for_All {
    position: absolute;
    top: 8px;
    background-color: transparent !important;
    border: none;
    font-size: 25px;
}
@media (max-width: 1490px) {
    .sukuk_item_board_all {
        flex-direction: column;
        /* gap: 20px; */
    }
    .respon_display_none {
        display: none;
    }
}
