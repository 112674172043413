.VIP {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.localAid_profile {
    padding: 40px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.personalInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.lastOnee {
    width: 50%;
}
@media (max-width: 900px) {
    .localAid_profile {
        padding: 0px
    }
}
