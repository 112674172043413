.swiper-container-event{
    width: 100% !important;
}
.swiper-container-event .swiper-wrapper
{
    justify-content: space-evenly;
    gap:20px;
    height: 250px;
    margin: 20px;
}
.swiper-slide-event{
    width: 20% !important;
    background-color: rgb(255 255 255 / 39%);
    border-radius: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;

}

@media (max-width: 1150px) {
    .swiper-container-event .swiper-wrapper
{
    height: 200px;
}
}

@media (max-width: 700px) {
    .swiper-slide-event{
        width: 30% !important;    
    }
}