.popUp .modal-dialog-centered .modal-content {
    background-color: transparent;
    border: none;
}
.modal-backdrop.show {
    opacity: 0.8;
    z-index: 1000002;
}
.cssmarquee_arab, .cssmarquee, .marquee, .marquee_eng, .normalDiv {
    min-height: 34px;
}
.camp_amount {
    color: #fff;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.donation_img_forBanner {
    width: 160px;
    height: 120px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border: 2px solid white;
}
.titles {
    min-height: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.titles label {
    color: white;
}
.mobBtn_display_none {
    display: none;
}
.popUp .modal-dialog-centered .modal-content .modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
}
.btn-close {
    opacity: 1;
}
.modal {
    z-index: 10000000;
}
.spin_popUp_for_mob {
    height: 60px;
    width: 60px;
    padding: 25px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: center;
    font-weight: 900 !important;
    border: none;
    transition: all .3s ease-in-out;
}
.choosen_amount_for_mob {
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
}
.popUp_btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.popUp_btns button {
    width: 80%;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
}
.popUP_outline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    transition: all .3s ease-in-out;
}
.popUp {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
@media screen and (min-width:900px){
    .popUP_outline:hover{
        color: #ee4236;
        transform: scale(1.05); 
    }
    .spin_popUp_for_mob:hover{
        background-color: #ee4236 !important;
        transform: scale(1.1); 
        color: white !important;
        border: 1px solid #ffff;
        box-shadow: 0px 0px 5px 2.5px #616160;
    }
}

.newPopUpBtn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.react-multi-carousel-list {
    width: 100%;
}
.display_in_pop_up {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}
.popUp .modal-header {
    border: none;
    display: none;
}
.popUp .modal-header .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
@media (max-width: 1200px) {
    #login_sign form {
        padding: 10px;
        width: 100%;
    }
    .social_media_log {
        width: 100%;
    }
    /* .ERC_logo_login_sign {
        width: 200px;
        height: 50px;
    } */
    /* .social_media_log img {
        height: 30px;
        width: 30px;
    } */
    .popUp .modal-header {
        display: flex;
    }
    .donation_img_forBanner {
        width: 130px;
        height: 90px;
    }
    .spin_popUp_for_mob {
        height: 40px;
        width: 40px;
    }
}
@media (max-width: 900px) {
    .mobBtn_display_none {
        display: flex;
    }
}
.Toastify__toast-container {
    z-index: 1000000000000 !important;
    }
.Toastify__toast--error {
    
    z-index: 1000000000000 !important;
}

@media (max-width: 950px) {
    .popUp_btns button {
        font-size: 15px;
    }
}

/* 
.Toastify__toast--error {
    border: 1px solid #EB5757;
    border-radius: 50px !important;
    background: #FAE1E2 !important;
}
.Toastify__toast--error::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success {
  border: 1px solid #3A9EA5 !important;
  border-radius: 50px !important;
  background: #F0F9FA !important;
}
.Toastify__toast--success::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #E78326  !important;
  border-radius: 50px !important;
  background: #FADFC5 !important;
}
.Toastify__toast--warning::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}  
.Toastify__toast--warning::after {
  position: absolute;
  color: #E78326;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444C63    ;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
} 
.Toastify__toast > button>  svg {
    display: none;
} */
@media (max-width: 1117px) {
    .marquee label{
        font-size: 13px;
    }
    .marquee_eng label{
        font-size: 13px;
    }
    .normalDiv label{
        font-size: 13px;
    }
    .cssmarquee_arab {
        font-size: 13px;
    }
    .cssmarquee {
        font-size: 13px;
    }
}
