.becomeASpon {
    width: 100%;
    background-color: #fcfcfc;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-weight: 900;
}
#becomeASpon_sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 5px;
}
@media (max-width: 1150px) {
    #becomeASpon_sec {
        padding: 10px;
        gap: 10px;
    }
}

.all_section_title {
    font-weight: bolder;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 0.05rem #00000087;
}