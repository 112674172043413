.relatives_display {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 20px;
    padding: 20px;
    align-items: center;
    justify-content: center;
}
.column_for_relatives {
    display: flex;
    align-items: center;
    gap: 10px;
}
.flexForRelatives {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
@media (max-width: 700px) {
    .relatives_display {
        grid-template-columns: 90%;
    }
}