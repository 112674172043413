#human_cases_display_page {
    background-color: #fdfdfd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.header_of_human_cases {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}
.body_of_human_cases {
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 20px;
    row-gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.humanCaseSearchInput {
    box-shadow: 1px 0px 8px rgb(128 128 128 / 20%);
}
@media (max-width: 1000px) {
    .body_of_human_cases {
        grid-template-columns: 100%;
        min-height: unset;
        row-gap: 50px;
        padding: 10px;
    }
}
@media (max-width: 900px) {
    .header_of_human_cases {
        display: none;
        flex-direction: column;
        padding: 10px;
    }
}