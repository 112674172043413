#banner {
    height: 64vh;
}
.carousel {
    width: 100%;
    /* min-height: 400px; */
}
.banner-taptodonate{
    font-weight: 500;
}
.btn-donate {
    border-radius: 30px;
    padding: 3px 10px 3px 10px;
    background-color: #f7f7f7 !important;
    color: rgba(0, 0, 0, 0.384);
}
.btn-donate:hover, .btn-donate:focus {
    background-color: #e43c3c !important;
    color: white;
}
#banner .carousel-inner {
    border-radius: 0px;
}

#banner .carousel-control-next,
#banner .carousel-control-prev {
    background: none !important;
    width: 3%;
    margin: 1%;
    transition: all .4s ease-in-out;
} 

@media screen and (min-width:1150px){
    #banner .carousel-control-next:hover, 
    #banner .carousel-control-prev:hover { 
        border-radius: 50px;
        cursor: pointer;
        transform: scale(1.25); 
  } 
    #banner .carousel-control-prev, #banner .carousel-control-prev-icon {
         background-image: url("../../../../assets/carousel_prev.png"); 
    }
   
   #banner .carousel-control-next, #banner .carousel-control-next-icon {
        background-image: url("../../../../assets/carousel_next.png");
    }
} 
@media screen and (max-width:900px){
    #banner .carousel-control-prev, #banner .carousel-control-prev-icon {
        background-image: none; 
    }
    #banner .carousel-control-next, #banner .carousel-control-next-icon {
        background-image: none;
    }
}
#banner .carousel-indicators {
    background-color: #fdfdfd;
    margin: 0px;
}
#banner .swiper-pagination-bullet{
    background-color: rgb(99, 99, 99);
    width: 50px;
    height: 3px;
    margin: 20px 0px 0px 0px;
    border-radius: unset;
    cursor: pointer;
}
#banner .carousel-caption {
    padding-bottom: 2rem;
}
#banner .swiper-pagination-bullet-active{
    background-color: #ee4236;
}
.donate_btn_icon_banner {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
}
.doto_banner_hp{
    height: 20px !important;
    width: 20px !important;
}
@media (max-width: 900px) {
    .banner_for_home_page {
        height: 45vh !important;
        object-fit: fill !important;
        min-height: 45vh !important;
    }
    .fonmt_for_banner, .btn_for_text {
        font-size: 15px;
        font-weight: 900;
    }
    #banner {
        height: 49vh;
    }
}

#banner .swiper-wrapper {
    margin-left: 0px !important;
}