.all-orphan-filter-list {
    display: flex;
    flex-direction: column;
}
.orphan-filter {
    display: flex;
    justify-content: space-between;
}
.orphan-filter-image {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    object-fit: cover;
}
.orphan-filter-text-name {
    font-size: x-large;
}
.orphan-filter-text-id {
    font-size: small;
    color: #80808066;
}
.orphan-filter-text {
    width: 60%;
}


.orphan-save-section {
    display: flex;
    justify-content: center;
}
.orphan-save-section button {
    flex: 0.8;
    justify-content: center;
}
.close-transaction-filter {
    cursor: pointer;
    position: absolute;
    background-color: #8080803b;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear-all-button {
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 4px;
    border: none;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
}
.orphan-save-button {
    background-color: red;
    border: none;
    border-radius: 20px;
    color: white;
}