.modal_body_for_donation_final {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: #fdfdfd;
    border-radius: 10px;
}
.donation_final_popUp_btns_wraper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.donation_final_popUp_btn_first {
    width: 100%;
    border-radius: 15px;
    padding: 5px;
    border: none;
    background-color: black;
    color: white;
}
.donation_final_popUp_btn_second {
    width: 100%;
    border-radius: 15px;
    padding: 5px;
    border: none;
    background-color: #ee4236;
    color: white;
}
.donation_final_popUp_btn_second_logout {
    width: 100%;
    border-radius: 15px;
    padding: 5px;
    border: none;
    background-color: #0518e9;
    color: white;
}
.donation_pop_up_body .modal-dialog .modal-content {
    border: none;
}