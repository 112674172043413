.orphan-filter-select-mark {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: rgba(76, 76, 76, 0.975);
    border-radius: 100%;
    border: none;
}


.add-check.orphan-filter-select-mark {
    background-color: rgba(255, 0, 0, 0.975);
    
}