.sukuk_gradient{
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgb(255, 255, 255) 100%);
    position: absolute;
    height: 379.5px;
    z-index: 5;
}
.img_sukuk_wraper{
    overflow: hidden;
    width: 100%;
}
.img_sukuk_wraper_mobile {
    width: 100%;
    overflow: hidden;

}
.apple_pay_icon {
    height: 25px;
}
.btnFontSml {
    font-size: 14px;
}
.sukuk_item_board_mobile {
    width: 100%;
    padding: 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.sukuk_item_mobile {
    width: 100%;
    display: grid;
    grid-template-columns: 48% 52%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 0px 5px #88888844;
    transition: all .2s ease-in-out;
    overflow: hidden;
}
.img_sukuk_wraper .sukuk_item_img {
    transform-origin: 55% 55%;
    transition: transform 5s ease;
    border-radius: 5px;
  }
  .img_sukuk_wraper_mobile img {
    transform-origin: 55% 55%;
    transition: transform 5s ease;
    border-radius: 5px;
  }
@media screen and (min-width:900px){
    .img_sukuk_wraper:hover .sukuk_item_img {
    transform: scale(1.5);
    border-radius: 5px;
  }
}
@media screen and (min-width:900px){
    .img_sukuk_wraper_mobile:hover img {
    transform: scale(1.5);
    border-radius: 5px;
  }
}
.sukuk-img{
    width: 150px;
}
.text-bold{
    font-weight: 700;
}
.sukukCom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.sukuk_item {
    width: 100%;
    display: grid;
    grid-template-columns: 48% 52%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px -1px 5px #88888844;
    transition: all .2s ease-in-out;
    
}

#sukuk_sec .swiper-wrapper{
    overflow: visible!important;
}

.btn_for_sukuk {
    border-radius: 10px;
    background: none;
    min-height: 46px;
    min-width: 46px;
    background-color: transparent;
    border: none;
    transition: all .3s ease-in-out;
}

@media screen and (min-width:900px){
    .btn_for_sukuk:hover { 
        cursor: pointer;
        transform: scale(1.2); 
        box-shadow: 0px 0px 10px 1px #616160;
  }
}

.btn_for_sukuk img{
    background: none !important;
    border:none;
    transition: all .2s ease-in-out;
}

.sukuk_btn_img {
    background: none;
    height: 100%;
    width: 45%;
    object-fit: cover;
}
.sukuk_btn_img_sms {
    height: 100%;
    width: 45%;
}
.sukuk_btn_img_apple {
    width: 30px;
    height: 30px;
}
.sukuk_btn_img_general {
    width: 30px;
    height: 30px;
}

.apple_pay {
    width: 30px !important;
    height: 20px !important;
    object-fit: fill;
}
.sukuk_item_img {
    object-fit: cover;
    height: 332px;
    width: 100%;
    /* border-top-right-radius: 100px 100px;
    border-bottom-right-radius: 100px 100px;
     border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;  */
    opacity: 0.85;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.sukuk_item_board {
    width: 100%;
    /* height: 100%; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
}
.sukuk_item_board_time {
    min-height: unset;
}
.sukuk_item_board_payment {
    min-height: 98px;
}
.sukuk_item_board_time, .sukuk_item_board_payment {
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sukuk_inc_Dec {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.px30_btn {
    border-radius: 30px;
}
.px50_btn {
    border-radius: 50%;
    font-size: 30px;
}

/* .modal-body .sukukinc, .modal-body .sukukdec{
    padding-bottom: 10px !important;
} */

.sukuk_item .sukukinc, .sukuk_item .sukukdec{
    /* padding-bottom:1px !important; */
    font-size: 1.8rem;
    border: 2px solid #f7f7f7;
}

.container_human .sukukinc, .container_human .sukukdec{
    font-size: 2rem;
    background-color: #ffffff;
    border: 2px solid #f7f7f7;
}
.sukukinc, .sukukdec {
    height: 39px;
    width: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color:#ee4236;
    font-size: 1.5rem;
    transition: all .3s ease-in-out !important;
}
@media screen and (min-width:900px){
    .sukukinc:hover, .sukukdec:hover { 
        color: #ffffff !important;
        background-color: #ee4236 !important;
        cursor: pointer;
        transform: scale(1.1); 
        box-shadow: 0px 0px 5px 2px #616160;
  }
}

.px30_btn:hover {
 background-color: #ee4236;
 color: white;
} 
.px50_btn:hover {
 background-color: #ee4236;
 color: white;
} 
.px30_btn:focus {
    background-color: #ee4236;
    color: white;
   } 
   .px50_btn:focus {
    background-color: #ee4236;
    color: white;
   } 
.like_btn_for_sukuk {
    position: absolute;
    top: 8px;
    /* left: 16px; */
    background-color: transparent;
    border: none;
    font-size: 25px;
    z-index: 6;
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .like_btn_for_sukuk .fa-heart:hover { 
        color: #ee4236 !important;
        cursor: pointer;
        transform: scale(1.3); 
  }
}
.btn_label_for_sukul {
    min-height: 39px;
    text-align: center;
    font-size: 13px;
}
.btn-frequ {
    padding: 10px 20px !important; 
    width: 120px;
}
.btn-sukuk{
    background-color: #ececec ;
    color:#717171;
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .btn-sukuk:hover { 
        background-color: #ee4236 !important;
        color: #ffffff !important;
        cursor: pointer;
        transform: scale(1.15); 
        box-shadow: 0px 0px 3px 1px #717171;

  }
}
@media (max-width: 800px) {
    .btn_label_for_sukul {
        min-height: 22px;
        font-size: 7px;
        text-align: center;
    }
    #sukuk_slider .swiper-container
    .sukukCom {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-radius: 5px;
    }
    .sukuk_item_board_time, .sukuk_item_board_payment {
        min-height: unset;
    }
    .inc_dec_label_sukuk, .inc_dec_label_sukuk span {
        font-size: 11px !important;
    }
    .px50_btn {
        font-size: 11px;
    }
    .btn-frequ {
        padding: 5px 10px 5px 10px !important;
        font-size: 10px;
        height: 30px;
        min-width: 45px;
        z-index: 10000;
        border-radius: 10px !important;
        font-weight: 900;
    }
    .inc_dec_label_sukuk span {
        margin-left: 0px !important;
    }
    .sukuk-img{
        width: 100px;
    }
    .title_of_sukuk {
        font-size: 12px !important;
    }
    .px30_btn {
        font-size: 11px;
    }
    .sukukinc, .sukukdec {
        font-size: 15px !important;
        font-weight: 900;
    }
    .sukuk_btn_img {
        height: 20px;
        width: 10px;
        object-fit: fill;
    }
    .apple_pay {
        width: 20px !important;
        height: 10px !important;
        object-fit: fill;
    }
    .sukuk_item_img, .sukuk_gradient {
        height: 253px;
    }
    .sukuk_item_board {
        gap: 5px;
    }
    .noWidth {
        gap: 15px;
    }
    @media (max-width: 250px) {
        .noWidth {
            gap: 0px;
        }
    }
    /* .sukuk_item_board {
        width: 55%;
    } */
    .sukuk_btn_img_sms {
        height: 26px;
        width: 18px;
    }
    .apple_pay_icon {
        height: 20px;
    }
    .sukuk_btn_img_general {
        width: 22px;
        height: 20px;
    }
    .btn_for_sukuk {
        background-color: transparent;
        padding: 0px;
        min-height: unset;
    }
    .btn_with_text {
        gap: 5px !important;
    }
    .one_time {
        font-size: 7px !important;
    }
    .sukuk_item { 
        box-shadow: 0px 0px 1px transparent;
    
    }
}
@media (max-width: 330px) {
    .sukuk_item_mobile, .sukuk_item {
        grid-template-columns: 40% 60%;
    }
}
@media (max-width: 280px) {
    .sukuk_item_mobile, .sukuk_item {
        grid-template-columns: 100%;
    }
}