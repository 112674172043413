#projects_banner {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 30px 0px;
    justify-content: flex-end;
    gap: 10px;
    color: white;
}
#projects_page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobileOnly {
    display: none !important;
}
.switch_btn {
    padding: 15px;
    background-color: white;
    font-size: 17px;
    width: 100%;
    border: 0px;
    border-radius: 0px;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .switch_btn:hover { 
        transform: scale(1.03);
        background-color: #ee4236;
        box-shadow: 0px 0px 15px 1px #0f0f0f;
  }
}

#switch_project_page {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.btn-first {
        /* transform: scale(1.03); */
        color: white;
        background-color: #ee4236;
        box-shadow: 0px 0px 15px 1px #0f0f0f;
}
.search_input_for_projects {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search_input_for_projects input {
    width: 35%;
    border: none;
    padding: 10px;
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.search_input_for_projects input:focus {
    outline: none;
}
.search_input_for_projects button {
    padding: 10px;
    border: none;
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: white;
}
@media (max-width: 900px) {
    #projects_banner {
        display: none;
    }
    .mobileOnly {
        display: flex !important;
    }
    .switch_btn {
        font-size: 13px;
        padding: 11px;
    }
}