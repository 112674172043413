.donation_inc_Dec_bag {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    flex: 2;
    gap: 10px;
    height: 100%;
}
.dec, .inc {
    padding: 0.5px 9px;
    border-radius: 42%;
    background-color: #fa8b806e;
    color: #f31f0c;
    border: none;
    font-size: 20px;
    font-weight: bold;
}
.big_wraper_bag {
    box-shadow: 2px 0px 5px #88888836;
    border-radius: 30px;
}
.bag_item_com {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 30px;
    gap: 10px;
    width: 100%;
    height: 120px;
}
.bag_item_img {
    height: 90px;
    width: 119px;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
    align-self: flex-start;
}
.removeBtn_from_bag {
    background-color: transparent;
    border: none;
    font-size: 15px;
    color: #ee4236;
    background-color: #ee4236;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: absolute;
    top: -5px;
    right: 10px;
    z-index: 1001;
}
@media (max-width: 1280px) {
    .donation_inc_Dec_bag {
        padding: 0px !important;
    }
}
@media (max-width: 1150px) {
    .bag_item_img {
        width: 95px;
    }
}