.big_wraper_bag {
    box-shadow: 2px 0px 5px #88888836;
    border-radius: 30px;
    position: relative;
}
.bag_item_com {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 30px;
    gap: 10px;
    width: 100%;
    height: 120px;
}
.bag_item_img_orphans {
    height: 90px;
    width: 119px;
    border-radius: 20px;
    object-fit: cover;
    object-position: top;
    align-self: flex-start;
}
.country_Img_Bag {
    height: 30px;
    width: 30px;
    object-fit: cover;
}
.country_img_wraper_Bag {
    display: flex;
    align-items: center;
    gap: 5px;
}
.project_Details_bag {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 7;
}
@media (max-width: 1280px) {
    .donation_inc_Dec_bag {
        padding: 0px !important;
    }
}