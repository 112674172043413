@media only screen and (max-width: 600px) {
    .contactus-header {
        box-shadow: 0 5px 8px rgb(0 0 0 / 20%);
    }
}

@media only screen and (min-width: 600px) {
    .backb {
        display: none;
    }
}

.contactus-title {
    color: black;
    font-weight: 400;
    font-family: inherit;
    margin-right: auto;
    margin-left: auto;
}

.backb {
    margin-top: 8px;

}

.contactus-header {
    padding: 10px 25px 10px 25px;
    display: flex;
    justify-content: space-between;

}

.page-contents {
    padding: 14px;
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;

    /* background-color: white; */
}

.contactus-section-title {
    font-weight: 700;
    font-family: inherit;
    font-size: 20px;
}

.contct-info {
    color: black;
    font-family: inherit;
    font-weight: 300;
    font-size: 15px;
}

.ci-title {
    font-family: inherit;
    font-size: 15px;
    font-weight: 700;
}

.erc-address {
    font-size: 15px;
    font-family: inherit;
    font-weight: 300;
    color: black;
    margin-bottom: 25px;
}

.select-issue {
    border: none;
    width: 70%;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 0 10px #0e0d0d61;
    margin-bottom: 20px;
    -webkit-appearance: none;
}

.select-form {
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 10px;
}

.form-100 {
    border: none;
    width: 100%;
    padding: 6px;
    border-radius: 12px;
    box-shadow: 0 0 10px #0e0d0d61;
    margin-bottom: 20px;
    -webkit-appearance: none;
}

.sub-btn {
    background: #c7ced8;
    border: 1px solid #a1abb8;
    color: white;
    width: 100%;
    font-family: inherit;
    border-radius: 25px;
    padding: 10px;
    transition: all .2s ease-in-out;

}
@media screen and (min-width:1150px){
.sub-btn:hover{
    background-color: white;
    color: #ee4236;
    transform: scale(1.1); 
    box-shadow: 0px 0px 10px 1px #30302f;
}
}

select.select-issue::after {
    font-family: FontAwesome;
    content: '\f2d3';
    margin-left: -1.5em;
}
.contact_us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

/* } */