@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "arab_font";
  src: local("arab_font"),
    url('./fonts/GE_SS_TWO_LIGHT.ttf') format("truetype");
}
@font-face {
  font-family: "english_font";
  src: local("arab_font"),
    url('./fonts/Poppins-Light.ttf') format("truetype");
}
@font-face {
  font-family: "english_font_bold";
  src: local("arab_font"),
    url('./fonts/Poppins-Bold.ttf') format("truetype");
}
body .modal-open {
  width: 100%;
  padding-right: 0px !important;
  overflow-y: scroll !important;
}
input, .payment_numbers, .rccs__number, .rccs__name, .rccs__cvc {
  font-family: english_font !important;
}
.force_arabic_font, .sukukdec, .sukukinc {
  font-family: 'arab_font' !important;
}
input:focus, button:focus {
  outline: none;
}
.Bolded{
  font-weight: 900;
}
.centered{
  text-align: center;
}
.default_min_height {
  min-height: 85vh;
}
