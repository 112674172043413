#my_bag {
    padding: 10px;
    text-align: center;
}

#my_bag>h2 {
    display: none;
}

#my_bag_left_side {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.items_on_bag_display {
    width: 100%;
    height: calc(100vh - 360px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    overflow: auto;
    margin-bottom: 121px;
}

.my_bag_bottom {
    display: flex;
    flex-direction: column;
}

.my_bag_bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.donation_display_my_bag {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.swiper-container-vertical>.swiper-wrapper {
    width: 100%;
}

.swiper-container-vertical>.swiper-pagination-bullets {
    right: 0;
}

.swiper-pagination-bullet-active {
    background: #ee4236;
}

.my_total_bag {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.total_amount_for_bag {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 90%;
    padding: 10px;
    z-index: 10000;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.text_of_total_amount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text_of_total_amount label {
    font-size: 25px;
}
.removeBtn_from_bag {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
    transition: all .2s ease-in-out;
}
.dec, .inc{
    transition: all .2s ease-in-out;
}
.Donate_now_add_to_cart {
    width: 100%;
    padding: 10px;
    background-color: #ee4236;
    border-radius: 30px;
    border: none;
    color: white;
    font-weight: bold;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:1150px){
.Donate_now_add_to_cart:hover{
    background-color: white;
    color: #ee4236;
    transform: scale(1.1); 
    box-shadow: 0px 0px 20px 1px #30302f;
}

.removeBtn_from_bag:hover, 
.dec:hover, .inc:hover
{
    background-color: white;
    color: #ee4236 !important;
    box-shadow: 0px 0px 5px 3px #757575;
    }
}

.latePayment {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.vl {
    border-left: 6px solid green;
    height: 100%;
}

@media (max-width: 900px) {
    .latePayment {
        padding: 0px;
    }

    .title_to_be_none {
        display: none;
    }

    #my_bag_left_side {
        padding: 0px;
        align-items: center;
        border: none !important;
    }

    .total_amount_for_bag {
        align-self: center;
    }

    .my_bag_bottom {
        display: none;
    }

    .total_amount_for_bag {
        padding: 0px;
        position: fixed;
        bottom: 55px;
        background-color: white;
        width: 100%;
        padding: 10px;
        z-index: 10000;
    }

    .items_on_bag_display {
        margin-bottom: 176px;
        height: 100%;
    }

    #my_bag {
        padding: 0px;
        height: 100vh;
    }
}

.switch_project_page {
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1150px) {
    #my_bag>h2 {
        margin: 10px 0;
        display: block;
    }
}

@media (max-width: 900px) {
    .switch_project_page {
        display: flex;
    }
}