.select_mode {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9996;
    background-color: rgba(0, 0, 0, 0.801);
    transition: background-color 1s ease-in-out;
}
.select_mode h3 {
    margin-bottom: 0px;
}
.select_mode_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 250px;
}
.select_mode_btn button {
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 17px;
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 10px;
}
.select_mode_close_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 70px;
}
.select_mode_close_btn button {
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 17px;
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 10px;
}
@media (max-width: 1200px) {
    .select_mode_btn {
        bottom: 473px;
    }
    .select_mode_close_btn {
        bottom: 250px;
    }
}
@media (max-width: 600px) {
    .select_mode_btn {
        bottom: 584px;
    }
}
@media (max-width: 277px) {
    .select_mode_btn {
        bottom: 300px;
    }
}
@media (max-width: 900px) {
    .select_mode h3 {
        top: 77px !important;
    }
}