.switch_projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.switch_project_page_wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 20px;
  padding-bottom: 40px !important;
}
.main_project {
  display: flex;
  width: 100%;
  height: 100%;
}
.child_pdf_main {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #000;
  z-index: 99;
  opacity: 0.9;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 10px 30px;
  cursor: pointer;
}
.pdf_viewer {
  flex-basis: 50%;
  display: flex;
  justify-content: end;
}
.child_viewer {
  width: 90%;
  border: 6px solid #d9d9d9;
  border-radius: 20px;
  position: relative;
  overflow: scroll;
  cursor: pointer;
}
.pdf_iframe {
  width: 100%;
  height: 100%;
}
.date_pdf {
  display: flex;
  justify-content: flex-end;
  padding-right: 90px;
  width: 100%;
}
.date_pdf p {
  background-color: #f5f5f5;
  padding: 8px 20px;
  text-align: right;
  cursor: pointer;
  border-radius: 10px;
  color: #000;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 30px;
}
.image_pdf {
  width: 100%;
  height: 150vh;
}
.pdf_btns {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
.pdf_btns p {
  margin-bottom: 2px;
  /* padding: 10px 30px; */
  font-weight: 700;
  cursor: pointer;
}

.left_projects {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.projectss i {
  font-size: 90px;
}
.prevCustom {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transition: all 0.4s ease-in-out;
  position: absolute;
  left: 0px;
  cursor: pointer;
  color: #ee4236;
  background-color: white;
  background-color: white;
  width: 10px;
  padding: 10px;
  z-index: 10000;
}
.nextCustom {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transition: all 0.4s ease-in-out;
  position: absolute;
  right: 0px;
  cursor: pointer;
  color: #ee4236;
  background-color: white;
  padding: 10px;
  z-index: 10000;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.slider_image{
  width: 192px;
  height: 87px;
  margin-top: 20px;
}
.video_style_attachment{
background-color: #F9F9F9;
box-shadow: 0px 0px 20px 2px #88888836;
border-radius: 10px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center 
}

.pdf_style_attachement{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center ;
  background-color: #F9F9F9;
  box-shadow: 0px 0px 20px 2px #88888836; 
  border-radius: 10px 
}
.projectss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.flexCenter {
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.swiper_forDonations2 .swiper-wrapper {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch_donation_header_real .swiper_forDonations2 .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid gray;
}
.switch_donation_header_real .swiper_forDonations2 .swiper-slide:last-child {
  border-right: none;
}

.reportDetails {
  min-height: 200px;
  display: flex;
  flex-direction: row;
}
.reportLeft {
  display: flex;
  /* background-color: red; */
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
}
.reportRight {
  display: flex;
  /* background-color: blue; */
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
}

.reportDetailKey {
  display: flex;
}

.reportCustomKey,
.reportCustomValue {
  width: 50%;
}
.reportCustomValue {
  color: #ee4236;
}
.projectsSec {
  width: 80% !important;
  align-self: center;
}
@media (max-width: 1000px) {
  .projectsSec {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .switch_project_page_wraper {
    padding: 0px;
  }
  .switch_project_page_wraper h3 {
    display: none;
  }
  .switch_projects {
    padding: 10px;
  }
}
@media (max-width: 460px) {
  .reportDetails {
    flex-direction: column;
    gap: 10px;
  }
  .reportLeft,
  .reportRight {
    width: 100%;
    gap: 10px;
  }
  .slider_image{
    width: 370px;
    height: 130px;
    margin-top: 20px;
  }
}
.main_project .swiper-wrapper {
  margin-left: 0px !important;
}

@media screen and (max-width: 1300px) {
  .left_projects {
    width: 100%;
  }
  .pdf_viewer {
    width: 100%;
  }
  .main_project {
    flex-direction: column;
  }
  .child_viewer {
    width: 100%;
  }
  .date_pdf {
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
    padding-top: 18px;
}
.projectsSec{
  width: 100% !important;
}
}
