#all_sukuk_page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}
#ad_for_sukuk {
    background-color: rgb(226, 226, 202);
    padding: 40px;
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 10px;
}
.sukuk_logo {
    height: 150px;
    width: 100%;
    object-fit: fill;
}
.all_sukuk_page_display {
    display: grid;
    grid-template-columns: 45% 45%;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px;
}
@media (max-width: 1270px) {
    .all_sukuk_page_display {
        grid-template-columns: 100%;
    }
}
@media (max-width: 900px) {
    .all_sukuk_page_display {
        padding: 10px;
    }
    #ad_for_sukuk p {
        font-size: 11px;
    }
    #ad_for_sukuk {
        display: none;
    }
}
@media (max-width: 280px) {
    .sukuk_item {
        grid-template-columns: 100%;
    }
}
