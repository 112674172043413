.becomeASpon_com {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    margin-top: 40px;
    margin-bottom: 40px;
}


.becomeASpon_border{
    height: 180px;
    width: 180px;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    overflow: hidden;
}

.becomeASpon_com img{
    transform-origin: 45% 65%;
    transition: transform 3s ease;
}

@media screen and (min-width:700px){
    .becomeASpon_border:hover { 
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 0px 25px 3px #383838;
  }
  .becomeASpon_com img:hover {
    transform: scale(1.1);
  }
}
.become_label {
    text-align: center;
}

.spon_img {
    height: 180px;
    width: 180px;
    border-radius: 50%;
    border: 2px solid #edf2fe;
    object-fit: cover;
}
@media (max-width: 700px) {
    .become_label {
        font-size: 11px;
    }
    .spon_img {
        height: 120px;
        width: 120px;
    }
    .becomeASpon_border{
        height: 120px;
        width: 120px;
    }
}
@media (max-width: 400px) {
    .spon_img {
        height: 60px;
        width: 60px;
    }
    .becomeASpon_border{
        height: 60px;
        width: 60px;
    }
}