.about_us_table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
} 
.aboutWraper {
  padding: 20px;
}
ol li {
  display: list-item !important;
  font-family: 'english_font';
}
.order_list ol
{
  list-style: decimal;
}

.about_us_nav_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  background-color: transparent;
  border: 1px solid gray;
  border-bottom: none;
  font-weight: 600;
  background-color: transparent;
  color: black;
  text-align: left;
}
.about_us_nav_item:last-child {
  border-bottom: 1px solid gray;
}
.about_us_nav_item_active {
  background-color: #ee4236;
  color: white;
}
.navigation_menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.content_section {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.about_us {
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
}
@media (max-width: 900px) {
  .about_us_table {
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .aboutWraper {
    padding: 0px;
  }
  .ERc_about {
    object-fit: cover;
  } 
  .about_us {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .about_us_nav_item {
    border: none;
    border-radius: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .about_us_nav_item:last-child {
    border-bottom: none;
    margin-top: 10px;
  }
  #item_about_1 {
    font-size: 12px;
  }
  #item_about_5 {
    font-size: 13px;
  }
  #item_about_9 {
    width: 50%;
  }
}
@media (max-width: 900px) {
  #item_about_1 {
    font-size: 8px;
  }
  #item_about_5 {
    font-size: 9px;
  }
  .about_us_table {
    justify-content: space-evenly;
  }
  .about_us_nav_item {
    padding: 7px;
  }
  .content_section {
    text-align: justify;
  }
  .content_section h4 {
    text-align: center;
  }
}
@media (max-width: 250px) {
  .ERc_about {
    height: 150px !important;
    width: 150px !important;
  }
}
