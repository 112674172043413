.calendar-wrapper {
    /* max-width: 280px; */
    font: 100% system-ui;
    margin-top: 20px;
  }
  .calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
  }
  
  .first-day {
    grid-column-start: 3;
  }
  
  
  .month_year {
    /* padding-left: 10px; */
  }

  /* .event_modal_title {
    margin-left: 40%;
  } */
  .event_modal.modal-header {
    border: none;
  }
  
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  li {
    padding: 2px;
  }
  
.calendar-wrapper:nth-child(even) div  {
  justify-content: flex-end;
}
.calendar-wrapper:nth-child(odd) div  {

  align-self: flex-end;
}
.row-border{
  border-top: 1px solid rgb(232, 232, 232);
  grid-column: 1 / 8; /* this code makes the row stretch to entire width of the container */
}