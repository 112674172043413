.PhoneInputInput {
    background-color: transparent !important;
    direction: ltr;
    border: none;
    font-family: inherit !important;
}
.PhoneInputCountry {
    gap: 5px;
}

.Spinner-new {
    /* height: 100px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-border {
    height: 1rem;
    width: 1rem;
}