.donate_idea {
  display: flex;
  justify-content: space-between;
  .left_side_donate {
    flex-basis: 48%;
    padding: 80px 30px 80px 30px;
    .contents_donate {
      h1 {
        font-weight: 800;
      }
    }
  }
  .right_side_donate {
    flex-basis: 48%;
    display: flex;
    justify-content: center;
    align-items: end;
    position: relative;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      background-color: #ffe7e8;
      -webkit-clip-path: circle(69.8% at 82% 62%);
      clip-path: circle(69.8% at 82% 62%);
      width: 100%;
      height: 100%;
    }
    .image_donate {
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .child_donate_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 30px;
    padding: 40px;
    gap: 30px;
    label {
      border: 1px solid #000;
      text-align: left;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      background-color: white;
      color: black;
    }
    input,
    label,
    textarea {
      padding: 10px 10px;
      background-color: #efefef;
      border: none;
      &::placeholder {
        color: #000;
      }
    }
    select{
      padding: 10px 10px;
      background-color: #efefef;
      border: none;
      &::placeholder {
        color: #000;
      }
    }
    p {
      margin-bottom: 1px;
      text-align: center;
      font-size: 18px;
    }
    button {
      background-color: #b11818;
      width: 100%;
      border: none;
      padding: 10px 30px;
      font-size: 20px;
      border-radius: 10px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      align-self: center;
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 1200px) {
  .donate_idea {
    flex-direction: column-reverse;
    .left_side_donate {
      width: 100%;
      flex-basis: 50% !important;
      padding: 40px 20px 40px 20px;
    }
    .right_side_donate {
      width: 100%;
      flex-basis: 50% !important;
      &::after {
        // -webkit-clip-path: circle(86.9% at 10% 13%);
        clip-path: none;
      }
      .image_donate {
        z-index: 3;
        img {
          width: 55%;
          height: 55%;
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .donate_idea {
    flex-direction: column-reverse;
    .left_side_donate {
      width: 100%;
      flex-basis: 50% !important;
      padding: 40px 20px 40px 20px;
    }
    .right_side_donate {
      width: 100%;
      flex-basis: 50% !important;
      padding: 40px 20px 40px 20px;
      &::after {
        // -webkit-clip-path: circle(86.9% at 10% 13%);
        clip-path: none;
        // -webkit-clip-path:  circle(86.9% at 10% 13%);
        // clip-path: circle(86.9% at 10% 13%);
        border-bottom-right-radius: 500px;
      }
      .image_donate {
        display: flex;
        justify-content: center;
        z-index: 1;
        padding-bottom: 44px;
        transform: rotate(-34deg);
        img {
          width: 60%;
          height: 60%;
          object-fit: contain;
        }
      }
    }
  }
}
