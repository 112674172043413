.popUpLoading .modal-content {
    background-color: transparent;
    border: none;
}
.Spinner {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner-border {
    height: 3rem;
    width: 3rem;
}