#project_shares_display_page {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.header_of_human_cases {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.body_of_project_shares {
  display: grid;
  max-width: 1600px;
  width: 95%;
  margin: 0 auto;
  grid-template-columns: repeat(3, 30%);
  grid-auto-rows: 430px;
  padding-bottom: 2em;
  /* // grid-template-rows: 150px 150px; */
  column-gap: 50px;

  row-gap: 50px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  /* min-height: 100vh;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 20px;
    row-gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-items: center; */
}
.project-share-item {
  width: 100%;
}
@media (max-width: 1200px) {
  .body_of_project_shares {
    grid-template-columns: repeat(2, 45%);
  }
}
@media screen and (max-width: 800px) {
  .body_of_project_shares {
    grid-template-columns: repeat(1, 95%);
  }
}
@media (max-width: 900px) {
  .header_of_human_cases {
    display: none;
    flex-direction: column;
    padding: 10px;
  }
}
.search_input_project_share {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-share-textfield-section-wrapper {
  width: 90%;
  /* max-width: 500px; */
  display: flex;
  gap: 26px;
}
.search_input_project_share div input,
.search_input_project_share input {
  border: none;
  padding: 10px;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  flex: 1;
  background-color: #f4f4f4;
}
.search_input_project_share div button {
  /* padding: 10px; */
  border: none;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #f4f4f4;
  transition: all 0.5s ease-in-out;
}
.create_share_btn {
  background-color: #ee4236;
  box-shadow: 0px 2px 5px #d3d3d3;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px 10px 5px 10px !important;

  min-width: 45px;
  z-index: 10000;
  border-radius: 10px !important;
  font-weight: 900;
  transition: all 0.2s ease-in-out;
  border-color: #f8f9fa;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
}

.project_share_donation_text_field_text,
.create_share_btn,
.project_share_no_result_found_text {
  font-size: clamp(12px, 2vw, 16px);
}

@media screen and (min-width: 1150px) {
  .search_input_project_share div button:hover,
  .search_input_project_share button:hover {
    cursor: pointer;
    /* transform: scaleX(-1);  */
    background-color: white;
    color: #ee4236;
    transform: scale(1.05);
    box-shadow: 0px 0px 8px 3px #919191;
  }
}

@media (max-width: 700px) {
  .search_input_project_share div input,
  .search_input_project_share input {
    width: 80%;
  }
}
