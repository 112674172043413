.section_title_projects{
    font-weight: bold; 
    font-size: 25px;
    width: 100%; 
    text-align: left;
}
.submit_requ_header{
  margin-top:30px;
}
.partner_section{
  margin-top:30px;
}
.projects_compm{
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}
.addPaymentCardBtn {
  border: none;
}
.payment_img_icon {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.paymentMethod_Wraper {
  border-radius: 15px;
  box-shadow: 1px 0px 8px gray;
  min-height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.delete_token_button {
  background-color: #ee4236;
  height: 66px;
  width: 80px;
  padding: 15px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-method {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.PaymentM_body{
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 100vw;
    flex-wrap: nowrap;
}
.paymentMethodsAllWraper {
  width: 100%;
  display: grid;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 20px;
  gap: 20px;
  grid-template-columns: 40% 40%;
}
.PaymentM_body_content{
    min-height: 70vh;
    display: flex;  
    width: 100%;
    flex-direction: column !important;
}
.add-paymentM-btn{
    width: 90%;
    height:5vh;
    border-radius: 20px;
    background-color: #fff0f0;
    border:0;
    font-size:1rem;
    color:#ee3124;
    margin-bottom: 20px;
}
.plusCircle{
    margin-left:20px;
}
.cardTile-card{
    width: 90%;
    height:7.5vh;
    line-height: 7.5vh;
    border-radius: 20px;
    background-color: white;
    border:0;
    font-size:1rem;
    color:black;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 0px 8px #80808033;
} 
.cardTile-column {
    float: left;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .cardTile-row:after {
    content: "";
    display: table;
    clear: both;
  }
  .doto_mastercard{
    height:4vh;
    width: auto;
    visibility: visible;
  } 
  .cardTileFull{
    width: 100%;
    margin-bottom:10px;
  }

  .deleteBtnX{
    text-align: center;
    line-height: 7.5vh;
  }
  .fullwidthspan{
      width: 100%;
  }
  .cardTile-card90{
    width: 70%;
    height:7.5vh;
    line-height: 7.5vh;
    border-radius: 20px;
    background-color: white;
    border:0;
    font-size:1rem;
    color:black;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    float:left;
  }
  .deleteBtnPM{
    margin-left:5%;
    float:left;
    width: 10%;
    height:7.5vh;
    border-radius: 20px;
    background-color: red;
    border:0;
    font-size:1rem;
    color:white;
    margin-bottom: 20px;
  }
  .cardtiletitle{
    flex: 0 0 100%; /* Let it fill the entire space horizontally */
    height: 100px;
  
  }
  @media (max-width: 900px) {
    .submit_requ_header {
      display: none;
    }
    
    .paymentMethodsAllWraper {
      grid-template-columns: 100%;
    }
  }