.project_display_component {
    position: relative;
    display: flex;
    border: none;
    border-radius: 30px;
    align-items: flex-start;
    justify-content: center;
    transition: all .2s ease-in-out;
    width: -moz-fit-content;
    width: fit-content;
}

@media screen and (min-width:900px){
    .project_display_component:hover { 
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 0px 10px 1px #30302f;
  }
}
.rightSign_btn--active {
    position: absolute;
    display: flex;
    border-radius: 50%;
    font-size: 20px;
    background-color: #ee4236;
    top:-5px;
    left: 0px;
    color: white;
    height: 20px;
    width: 20px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all .3s ease-in-out;
}
.rightSign_btn{
    position: absolute;
    display: flex;
    border-radius: 50%;
    font-size: 1px;
    background:transparent;
    top:-5px;
    left:10px;
    color: transparent;
    height: 20px;
    width: 20px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all .3s ease-in-out;
}

.project_img_for_projects_page {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    filter: grayscale(100%);
    transition: all .1s ease-in-out;
}
.wraper_for_project_img {
    height: 250px;
    width: 200px;
    position: relative;
    border-radius: 20px;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .wraper_for_project_img:hover { 
        background-color: #30302f;
        cursor: pointer;
        transform: scale(1.05); 
        color: #ee4236 !important;
        box-shadow: 0px 0px 10px 1px #30302f;
  }
}
@media screen and (max-width:900px){
    .wraper_for_project_img:hover { 
        cursor: pointer;
        color: #ee4236 !important;
    box-shadow: 0px 0px 10px 1px #30302f;
  }
  }

.btn_img_label_projects {
    position: absolute;
    top: 91.5%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.397);
    padding: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .btn_img_label_projects:hover { 
        cursor: pointer !important;
        color: #ee4236 !important;
  }
}

@media (max-width: 1000px) {
    .wraper_for_project_img {
        height: 160px;
        width: 130px;
        border: 1px solid transparent;
    }
    .btn_img_label_projects {
        top: 89%;
        font-size: 11px;
    }
    .like_btn {
        top: 2px;
        right: 10px;
        font-size: 15px;
    }
}
@media (max-width: 700px) {
    .wraper_for_project_img {
        height: 120px;
        width: 100px;
        border: 1px solid transparent;
    }
    .btn_img_label_projects {
        top: 87%;
        font-size: 9px;
    }
    .like_btn {
        top: 2px;
        right: 10px;
        font-size: 15px;
    }
}


.rightSign_btn_arab--active {
    position: absolute;
    display: flex;
    border-radius: 50%;
    font-size: 20px;
    background-color: #ee4236;
    top:-5px;
    right:0px;
    color: white;
    height: 20px;
    width: 20px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all .3s ease-in-out;
}

.rightSign_btn_arab{
    position: absolute;
    display: flex;
    border-radius: 50%;
    font-size: 1px;
    background:transparent;
    top:-5px;
    left:-12px;
    color: transparent;
    height: 20px;
    width: 20px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all .3s ease-in-out;
}


@media (max-width: 1000px) {
    .rightSign_btn--active, 
    .rightSign_btn_arab--active {
        width: 10px;
        height: 10px;
        font-size: 10px;
        padding: 10px;
        left: 0px;
    }
}

@media (max-width: 1000px) {
    .swiper-slide .country_project_comp .rightSign_btn--active {
        width: 10px;
        height: 10px;
        font-size: 10px;
        padding: 10px;
        left: 0px;
    }
}

@media (min-width: 1000px){
    .swiper-slide .project_display_component .rightSign_btn_arab--active {
        margin-left: 25% !important;
    }
}

@media (max-width: 900px){
    .orphan_card_border .rightSign_btn_arab--active {
        margin-right: 20px !important;
    }
}

.charity-projects_title{
    text-transform: capitalize;
}