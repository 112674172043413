.svg_right_sign {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }
  
.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }
.circle_for_right {
      -webkit-animation: dash .9s ease-in-out;
      animation: dash .9s ease-in-out;
    }
.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check .9s .35s ease-in-out forwards;
      animation: dash-check .9s .35s ease-in-out forwards;
    }

.success {
      color: #ee4236;
      text-align: center;
      margin: 20px 0 40px;
      font-size: 1.25em;
    }

  
  
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }