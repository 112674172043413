.switch_projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.switch_project_page_wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;

}
.projectss i {
  font-size: 90px;
}
.prevCustom {
  background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    transition: all .4s ease-in-out;
    position: absolute;
    left: 0px;
    cursor: pointer;
    color: #ee4236;
    background-color: white;
    background-color: white;
    width: 10px;
    padding: 10px;
    z-index: 10000;
}
.nextCustom {
  background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    transition: all .4s ease-in-out;
    position: absolute;
    right: 0px;
    cursor: pointer;
    color: #ee4236;
    background-color: white;
    padding: 10px;
    z-index: 10000;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.projectss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.flexCenter {
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.swiper_forDonations2 .swiper-wrapper {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch_donation_header_real .swiper_forDonations2 .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid gray;
}
.switch_donation_header_real .swiper_forDonations2 .swiper-slide:last-child {
  border-right: none;
}

.reportDetails {
  min-height: 200px;
  display: flex;
  flex-direction: row;
}
.reportLeft {
  display: flex;
  /* background-color: red; */
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
}
.reportRight {
  display: flex;
  /* background-color: blue; */
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
}

.reportDetailKey {
  display: flex;
}

.reportCustomKey,
.reportCustomValue {
  width: 50%;
}
.reportCustomValue {
  color: #ee4236;
}
.projectsSec {
  width: 60%;
  align-self: center;
}
@media (max-width: 1000px) {
  .projectsSec {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .switch_project_page_wraper {
    padding: 0px;
  }
  .switch_project_page_wraper h3 {
    display: none;
  }
  .switch_projects {
    padding: 10px;
  }
}
@media (max-width: 460px) {
  .reportDetails {
    flex-direction: column;
    gap: 10px;
  }
  .reportLeft, .reportRight {
    width: 100%;
    gap: 10px;
  }
}