.category_comp {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px 0px 5px #88888836;
  height: 294px;
  transition: all 0.2s ease-in-out;
}
.landing_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  flex: 2;
  transition: all 0.2s ease-in-out;
  position: relative;
}

@media screen and (min-width: 900px) {
  .category_comp:hover {
    cursor: pointer;
    transform: scale(1.05);
    color: #ee4236 !important;
    box-shadow: 0px 0px 10px 1px #30302f;
  }
}

.landing_label {
  flex: 1 1;
  width: 100%;
  color: white;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  cursor: pointer;
  bottom: 40%;
  left: 5%;
}
.landing_label_ar {
  flex: 1 1;
  width: 100%;
  color: white;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  cursor: pointer;
  bottom: 40%;
  left: -7%;
}
@media screen and (max-width: 900px) {
  .category_comp:hover {
    color: #ee4236 !important;
    box-shadow: 0px 0px 10px 1px #30302f;
  }
}
.leftarrow-i {
  position: absolute;
  left: 20px;
  top: 30%;
}
.rightarrow-i-landing {
  /* margin-left: 10px; */
}
@media (max-width: 700px) {
  .landing_img {
    /* height: 160px !important; */
    flex: unset;
    object-fit: cover;
    height: 100%;
  }
  .category_comp {
    height: auto;
  }
}
.title-landing {
  font-size: 1.5rem;
}
@media (max-width: 700px) {
  .title-landing {
    font-size: 1rem;
  }
  .rightarrow-i-landing {
    margin-left: -5px;
    width: 40px;
  }
  .landing_label {
    bottom: 30%;
    left: 5%;
  }
  .landing_label_ar {
    bottom: 30%;
    left: -7%;
  }
}
