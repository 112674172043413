.sortChoice {
    display: flex;
    width: 100%;
    gap: 10px;
}
.sortBtn {
    flex: 1;
}
.sortBtn.sortActive {
    background-color: #ee4236;
}
.doneBtn.sortSaveBtn {
    width: 100%;
}


.sortBtn {
    background-color: #808080;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
    min-width: 100px;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .sortBtn:hover { 
        cursor: pointer;
        transform: scale(1.05);
        background-color: white;
        color: #ee4236;
        box-shadow: 0px 0px 10px 1px #30302f;
  }
}

