.volunteer_details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  min-height: 85vh;
}

.volunteer_details .volunteer_info {
  width: 100%;
  min-width: 360px;
  max-width: 420px;
  /* width: 350px; */
  /* height: 543px; */
  border-radius: 13px;
  background-color: rgba(235, 235, 235, 1);
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  display: flex;
  margin: 0px 10px 0px 10px;
}

.volunteer_details .volunteer_info_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px 5px 0px 5px;
}

.volunteer_details .volunteer_status {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.volunteer_details .volunteer_status_circle {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: rgba(41, 225, 38, 1);
}

.volunteer_details .whole_input {
  padding: 10px;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  transition: "all 1s";
}

.volunteer_details .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.volunteer_details .form_label {
  color: black;
  transition: all 1s;
  padding: 0px 10px 0px 10px;
  font-weight: 100;
}
.volunteer_details .volunteer_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.volunteer_details .edit-btn:hover {
  color:#e74c3c;
}
.volunteer_details .volunteer_pic {
  margin: 20px;
  height: 156px;
  width: 156px;
  border-radius: 100%;
  background: rgba(208, 208, 208, 1);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.volunteer_details .info_fields {
  background: rgba(217, 217, 217, 1);                                                   
  padding: 8px 7px;
  transition: all 1s;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

/* Languages Section */
.volunteer_details .volunteer_skills {
  margin: 20px 10px 20px 10px;
  width: 100%;
  min-width: 360px;
  max-width: 420px;
  /* width: 350px; */
  /* height: 250px; */
  border-radius: 13px;
  background-color: rgba(235, 235, 235, 1);
  flex-direction: column;
  padding: 20px;
  /* display: flex; */
}

.volunteer_details .volunteer_skills h5 {
  padding: 0px 10px; 
  margin: 0px;
}

.volunteer_details .language_row {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 30px 0px;
  flex-wrap: wrap;
}

.volunteer_details .language_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0px 0px;
  padding: 20px 10px;
  height: 27px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 1);
}

.volunteer_details .language_box_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0px 0px;
  padding: 20px 17.5px;
  height: 27px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 1);
}

.volunteer_details .language_box_btn:hover {
  background:rgba(235, 54, 39, 1);
  color: white;
}

.volunteer_details .skill_column {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 10px 0px;
  width: 100%;
}

.volunteer_details .skill_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 5px 0px 0px;
  padding: 20px 10px;
  height: 27px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 1);
}

.volunteer_details .skill_box_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 5px 0px 0px;
  padding: 20px 17.5px;
  height: 27px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 1);
}

.volunteer_details .skill_box_btn:hover {
  background:rgba(235, 54, 39, 1);
  color: white;
}


/* Events for you section */
.volunteer_details .volunteer_events {
  width: 100%;
  min-width: 360px;
  max-width: 580px;
  /* width: 550px; */
  /* height: 440px; */
  border-radius: 13px;
  background: rgba(235, 235, 235, 1);
  flex-direction: column;
  padding: 30px;
  /* display: flex; */
  margin: 0px 10px 0px 10px;
}

.volunteer_details .volunteer_events h4,
.volunteer_details .volunteer_events h6,
.volunteer_details .volunteer_events p
{
  margin: 0px;
}

.volunteer_details .volunteer_events h5 {
  margin: 0px 0px 2.5px 0px;
  color: rgba(177, 24, 24, 1);
  font-weight: 700;
  /* font-family: 'Nexa Bold'; */
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.volunteer_details .volunteer_event_tile {
  /* height: 70px; */
  width: 100%;
  margin: 10px 0px;
  padding: 5px 0px 0px 0px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.volunteer_details .volunteer_event_content {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  padding: 15px; 
}

.volunteer_details .text-btn{
  padding-left: 0px;
  color: black;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.volunteer_details .text-btn:hover{
  padding-right: 5px;
  color: rgba(177, 24, 24, 1);
}



.volunteer_details .volunteer_event_tile .red-btn {
  width: 100%;
  /* width: 165px; */
  /* height: 25px; */
  border-radius: 6px;
  background: rgba(177, 24, 24, 1);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.volunteer_details .volunteer_event_tile .red-btn:hover {
  transform: scale(1.03);
  opacity: 0.8;
}

.volunteer_details .volunteer_event_tile .red-outline-btn {
  width: 100%;
  /* width: 165px; */
  border-radius: 6px;
  background: white;
  border: 1px solid rgba(235, 54, 39, 1);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.volunteer_details .volunteer_event_tile .red-outline-btn:hover {
  transform: scale(1.03);
  background: rgba(177, 24, 24, 1);
  color: white;
}


/* Events for you section */
.volunteer_details .volunteer_reports {
  margin: 20px 10px 0px 10px;
  width: 100%;
  min-width: 360px;
  max-width: 580px;
  max-height: 180px;
  /* width: 550px; */
  /* height: 440px; */
  border-radius: 13px;
  background: rgba(235, 235, 235, 1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.volunteer_details .volunteer_reports h5{
    margin:0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    
}

.volunteer_details .report_row {
  display: flex;
  flex-direction: row;
}

.volunteer_details .profile_report{
    margin: 0px 10px;
    width: 100%;
    height: 64px;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 1);
    background: linear-gradient(0deg, #D9D9D9, #D9D9D9),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
}

.volunteer_details .profile_report:hover{
    /* opacity: 0.75; */
    transform: scale(1.05);
    background: #e74c3c;
    color: white;
}

.volunteer_details .profile_certificates{
    margin: 0px 10px;
    width: 100%;
    height: 64px;
    border-radius: 7px;
    background: white;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.volunteer_details .profile_certificates:hover{
  /* opacity: 0.75; */
  transform: scale(1.05);
  background: #e74c3c;
  color: white;
}

.volunteer_details .volunteer_portal{
    text-align: right;
    justify-content: flex-end;
}

.volunteer_details .volunteer_hours {
    margin: 20px 10px 20px 10px;
    height: 100%;
    width: 100%;
    min-width: 360px;
    max-width: 580px;
    max-height: 350px;
    /* width: 422px; */
    /* height: 302px; */
    border-radius: 13px;
    background: rgba(235, 235, 235, 1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.volunteer_details .chart_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* height: 100%; */
  width: 100%;
  max-height: 275px;
  min-width: 300px;
  max-width: 500px;
} 

.volunteer_details .hour_chart,
.volunteer_details .event_chart
{
  width: 50%;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  padding: 10px;
}

.volunteer_details .volunteer_files {
  margin: 20px 10px 40px 10px;
  width: 100%;
  min-width: 360px;
  max-width: 580px;
  max-height: 320px;
  /* width: 422px; */
  /* height: 302px; */
  border-radius: 13px;
  background: rgba(235, 235, 235, 1);
  flex-direction: column;
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
}

.volunteer_details .volunter_file_tile {
  /* width: 349px; */
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  margin: 10px 0px;
}

.volunteer_details a,
.volunteer_details button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
  outline: none;
}

.volunteer_details a:focus,
.volunteer_details .btn:focus,
.volunteer_details button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}


.volunteer_details .volunter_file_tile:hover {
  --shadow-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.05);
}

.volunteer_details .volunter_file_tile h6 {
  margin: 0px;
}
.event-description {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.event-description .tooltip-text {
  visibility: hidden;
  width: 200px; /* Adjust based on your layout */
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -120%; /* Position above the text */
  
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.event-description:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 900px) {
  .volunteer_details {
    padding: 0px;
  }
}

@media (max-width: 991px) {
  .volunteer_details .volunteer_info {
    max-width: 580px;
  }
  .volunteer_details .volunteer_skills {
    max-width: 580px;
  }
}
