.headerForMob_sharing {
    position: relative;
    background-color: white;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-shadow: 2px 0px 8px rgba(128, 128, 128, 0.336);
    height: 55px;
    z-index: 10000;
}
.backHeader {
    color: #ee4236;
}
.sharingBtn_mob {
    background-color: white;
    border: none;
    position: absolute;
    right: 5px;
}
.backBtn_forMobHeader {
    cursor: 'pointer';
    font-size: '20px';
    position: absolute;
    left: 5px;
    border: none;
    background-color: transparent;
}
@media (max-width: 900px) {
    .headerForMob_sharing {
        display: flex;
    }
}

.fa-user, fa-heart, .fa-shopping-bag, .fa-bell {
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .header_btns  .fa-shopping-bag:hover,
    .header_btns .fa-heart:hover, 
    .header_btns  .fa-user:hover,
      .header_btns .fa-bell:hover { 
        color: white !important;
        cursor: pointer;
  }
}


.fa-search{
    transition: all .5s ease-in-out;
}

@media screen and (min-width:900px){
    .fa-search:hover { 
        cursor: pointer;
      /* transform: scaleX(-1);  */
      
  }
}