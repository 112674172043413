#login_sign {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 5px;
    padding: 20px;
}
#login_sign form {
    width: 50%;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    /* filter: grayscale(100%); */
}
.NeedToOtp {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.btn_for_Resend_OTP {
    color: #ee4236;
    background-color: transparent;
    border: none;
    transition: all .3s ease-in-out;
}
#login_sign .input {
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#login_sign .input input {
    border: 0px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #47464648;

}
#login_sign .input label {
    color: #7c7c7c;
    opacity: 1;
    font-size: 1.25rem;
}
#login_sign .input a {
    color: #cc0000;
    align-self: flex-end;
    font-weight: bold;
}
.whole_input {
    background-color: #f8f8f8;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
    border-radius: 5px;
}
.btn_for_form {
    min-height: 45px;
    width: 100%;
    border-radius: 30px;
}
.hrs {
    width: 100%;
    display: flex;
    gap: 10px;
}
.hrs hr {
    flex-grow: 1;
    color: #cc0000;
}

#google_login_logo.login_sign_social_logos{
    border: none;
    background: none;
    background-color: transparent;
}

.login_sign_social_logos {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    transition: all .2s ease-in-out;
}

.opt_confirm_back_btn{
    background-color: gray;
    border: none;
    padding: 7px;
    width: 90px;
    color: white;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .login_sign_social_logos:hover{
        transform: scale(1.05); 
        box-shadow: 0px 0px 5px 3px #8a8a8a;
    }
    .ERC_logo_border_inner:hover{
        /* transform: scale(1.05);  */
        box-shadow: 0px 0px 8px 3px #919191;
    }
    .confirm_otp:hover{
        border-radius: 5px;
        background-color: white;
        color: #ee4236;
        transform: scale(1.05); 
        box-shadow: 0px 0px 8px 3px #919191;
    }
    .btn_for_Resend_OTP:hover{
        border-radius: 5px;
        color: #ee4236;
        box-shadow: 0px 0px 2px 2px #919191;
    }
    .opt_confirm_back_btn:hover{
        color: #8a8a8a !important;
        background-color: white !important;
        box-shadow: 0px 0px 2px 2px #919191;
    }
}
.social_media_log {
    padding: 20px;
    width: 50%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* gap: 30px; */
    justify-content: space-evenly;
}
.uaeLoginImg {
    width: 370px !important;
    height: auto !important;
    object-fit: contain;
}

.NeedToOtp .ERC_logo_border{
    margin-left: 0%;
}
.ERC_logo_border{
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}
.ERC_logo_border_inner{
    cursor: pointer;
    transition: all .3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    width: 150px;
    height: 150px;
}
.ERC_logo_login_sign {
    cursor: pointer;
    align-self: center;
    width: 100px;
    height: 133px;
    object-fit: fill;
}
.otp_input_wraper {
    display: flex;
    box-shadow: 1px 0px 8px #47464633;
    border-radius: 5px !important;
}
.input_for_verfication_code {
    border: none;
    background-color: white;
    padding: 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.confirm_otp {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #ee4236;
    border: none;
    color: white;
    padding: 7px;
    transition: all .3s ease-in-out;
}
.form-text {
    background-color: transparent;
    border: none;
    align-self: flex-end;
}
@media (max-width: 900px) {
    #login_sign {
        padding: 5px;
    }
    #login_sign form {
        padding: 10px;
        width: 100%;
    }
    .social_media_log {
        width: 100%;
    }
    .ERC_logo_login_sign {
        width: 65px;
        height: 85px;
    }
    
    .NeedToOtp {
        width: 100%;
        padding: 0px;
    }
    .otp_input_wraper {
        width: 100%;
    }
    #login_sign {
        /* margin-bottom: 76px; */
        min-height: unset;
    }
    .input_for_verfication_code {
        width: 100%;
    }
    #login_sign form {
        gap: 15px;
    }
}
.btn-gradientlogin{
    background: rgb(198,206,216);
    background: linear-gradient(90deg, rgba(198,206,216,1) 0%, rgba(161,171,187,1) 100%);
    border: 2px solid #a1abb8;
    font-weight: bold;
    color:white;
    transition: all .2s ease-in-out;
}
.greyscalecolor{
    color:#9e9e9e !important;
}
.greyborder{
    border: 2px solid #a1abb8;
    font-weight: bold;
    color:#9e9e9e !important;
    transition: all .2s ease-in-out;
}
.blackcolor{
    color: black !important;
}

@media screen and (min-width:900px){
    .greyborder:hover{
        background: rgb(198,206,216);
        background: linear-gradient(90deg, rgba(198,206,216,1) 0%, rgba(161,171,187,1) 100%);
        border: 2px solid #a1abb8;
        font-weight: bold;
        color:white !important;
    }

    .btn-gradientlogin:hover{ 
        background: white;
        border: 2px solid #ee4236;
        font-weight: bold;
        color:#ee4236 !important;
        /* box-shadow: 0px 0px 5px 2px #a3a3a1; */
    }
}
.fontawesomeoutline{
    color: transparent !important; 
    -webkit-text-stroke-width: 2px !important; 
    -webkit-text-stroke-color: #9e9e9e !important;
}
@media (max-width: 430px) {
    .uaeLoginImg {
        width: 200px !important;
    }
    .social_media_log img {
        height: 40px;
        width: 40px;
    }
}