#events_sec {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.section_title_events{
    padding: 20px 20px 0px 20px;
}
.project_header {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background-size: cover;
    gap: 10px;
    background-position: center;
    filter: brightness(95%)
}

.project_header_btn {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px 80px;
    border: 1px solid transparent;
    border-radius: 5px;
    color: white;
    margin:20px;
    transition: all .3s ease-in-out;
}

#projects .carousel-control-next,
#projects .carousel-control-prev {
    background: none !important;
    margin: 30px;
    width: 40px;
    margin-top:15%;
    margin-bottom: 15%;
    transition: all .4s ease-in-out;
}

@media screen and (min-width:900px){
    .project_header_btn:hover { 
        cursor: pointer;
        transform: scale(1.1); 
        box-shadow: 0px 0px 5px 5px #383838 !important;
  }

    #projects .carousel-control-next-icon {
        background-image: url("../../../../assets/carousel_next.png");
       
    }
    #projects .carousel-control-prev-icon {
        background-image: url("../../../../assets/carousel_prev.png");
    }
    #projects .carousel-control-next:hover, 
    #projects .carousel-control-prev:hover {
        border-radius: 50px;
        cursor: pointer;
        /* transform: scale(1.1);  */
        box-shadow: 0px 5px 10px 1px #616160;
  }
}
.projects_display {
    min-height: 400px;
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}
#projects {
    width: 100%;
    padding: 45px;
}
#projects .carousel-indicators, #projects_for_Mobile .carousel-indicators {
    background-color: transparent;
    margin-bottom: 0px;
    align-items: center;
}
@media (max-width: 330px) {
    #projects .carousel-indicators, #projects_for_Mobile .carousel-indicators {
        margin-bottom: -10px;

    }
}
@media (max-width: 460px) {
    .project_header_btn {
        padding: 5px;
        margin:0px;
        font-size: 13px;
    }
    .project_header label {
        font-size: 17px !important;
        text-align: center;
    }
}
#projects .carousel-indicators [data-bs-target], #projects_for_Mobile .carousel-indicators [data-bs-target] {
    background-color: rgb(99, 99, 99);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 20px 5px 0px 0px
}
#projects .carousel-caption, #projects_for_Mobile .carousel-caption {
    padding-bottom: 3rem;
}
#projects .carousel-indicators .active, #projects_for_Mobile .carousel-indicators .active {
    background-color: #ee4236;
}
/* #projects .carousel-control-next, #projects .carousel-control-prev, #projects_for_Mobile .carousel-control-next, #projects_for_Mobile .carousel-control-prev {
    width: 2%;
    opacity: 1;
} */

#projects_for_Mobile {
    display: none;
}
@media (max-width: 1600px) {
    .project_header {
        height: 250px;
    }
    #projects {
        padding: 0px;
    }
}
/* @media (max-width: 1100px) {
    .projects_display {
        grid-template-columns: auto auto;
    }
}
@media (max-width: 750px) {
    .projects_display {
        grid-template-columns: auto auto;
    }
} */
@media (max-width: 250px) {
    .projects_display {
        grid-template-columns: auto;
    }
}
@media (max-width: 1150px) {
    #events_sec {
        padding: 10px;
        gap: 10px;
    }
    .section_title_events {
        padding: 10px 10px 0px 10px;
        font-size: 17px !important;
    }
    #projects {
        display: none;
    }
    #projects_for_Mobile {
        display: flex
    }
    .projects_display {
        gap: 10px;
        padding: 10px;
        min-height: 310px;
    }
}

.all_section_title {
    font-weight: bolder;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 0.05rem #00000087;
}