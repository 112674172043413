.story_component {
    height: 230px;
    width: 180px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
    color: white;
    filter: grayscale(100%);
    font-size: 18px;
    border: none;
    border-radius: 15px;
    transition: all .3s ease-in-out;
    margin: 10px 0px 10px 0px;
}
@media (max-width: 900px) {
    .story_component {
        width: 100px;
        height: 150px;
        font-size: 11px;
        padding: 5px;
    }
}

@media screen and (min-width:1150px){
    .story_component:hover{
        /* background-color: white; */
        /* color: #ee4236 !important; */
        /* transform: scale(1.1);  */
        box-shadow: 0px 0px 10px 2px #30302f;
        filter: none !important;
    }
}