.newsModalDialog {
  max-width: 900px !important;
}
.newsModalBody {
  padding: 10px;
}
.newsModalDialog .modal-header {
  border-bottom: none;
}
.newsModalContent {
  border-radius: 5px;
}
