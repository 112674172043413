.donation_inc_Dec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    border-radius: 18px;
}
.liked_donation_section .label_of_price{
    margin-bottom: 10px !important;
}
.liked_donation_section .donation_item{
    width: fit-content;
    /* border-bottom-left-radius: 36px;
    border-bottom-right-radius: 36px; */
    color: black;
    background-color: white;
    box-shadow: 0px 0px 20px 5px #7a7a7a42 !important;
}
.liked_donation_section .donation_details{
    box-shadow: 0px 0px 0px 0px !important;

}
.dd-bottom-red{
    width: 100%;
}
.scroll_contant{
    overflow:hidden;
    width:190px;
    background:transparent;
    white-space : nowrap;
  }
.single_donate_btn {
    height: 100%;
    width: 100%;
    border: none;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    background-color: #ee4236;
    color: white;
    font-weight: bold;
    padding: 10px;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .single_donate_btn:hover{
        background-color: white;
        color: #ee4236;
        box-shadow: 0px 0px 20px 1px #30302f;
    }
    }
.donation_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-color: white;
    box-shadow: 1px 1px 8px #88888854;
}
.donation_img {
    width: 190px;
    height: 140px;
    object-fit: cover;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
}
.like_btn {
    position: absolute;
    z-index: 1000;
    top: 8px;
    right: 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 25px;
    font-weight: 900;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    align-items: center;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .like_btn .fa-heart:hover{
        color: #ee4236 !important;
        transform: scale(1.2); 
}
}

.donation_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    border-radius: 18px;
    box-shadow: 0 0 10px #aaaaaa;
    margin-top: 15px;
}
.icon_for_donation {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: transparent;
}
.donation_com {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: all .2s ease-in-out;
}
.donate_btn_wrap {
    border: none;
    z-index: 1000;
    background-color: transparent;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: -30px;
    margin-top: -5px;
    align-items: center;
    padding: 0px;
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .donate_btn_wrap:hover{
        color: #ee4236;
        transform: scale(1.18); 
    }
}

.img_donation_wraper{
  overflow: hidden;
}

.img_donation_wraper img{
    transform-origin: 45% 65%;
    transition: transform 1.5s ease;
}

@media screen and (min-width:900px){
    .img_donation_wraper:hover img{
        transform: scale(1.2);
    }
}

@media (max-width: 1111px) {
    .donation_img {
        width: 130px;
        height: 110px;
        border-top-right-radius: 15px !important;
        border-top-left-radius: 15px !important;
    }
    .img_donation_wraper {
        width: 130px !important;

    }
    .icon_for_donation, .donate_btn_wrap {
        height: 35px;
        width: 35px;
    }
    .donate_btn_wrap {
        margin-right: -22px;
        margin-top: 0px;
    }
    .single_donate_btn {
        font-size: 11px;
        padding: 5px;
        width: 100%;
        border-bottom-right-radius: 18px !important;
        border-bottom-left-radius: 18px !important;
    }
    .donation_inc_Dec {
        justify-content: flex-start;
        gap: 10px;
        border-radius: 18px;
    }
    .label_of_title, .label_of_price {
        font-size: 11px !important;
        height: 100%;
        line-height: unset !important;
    }
    .label_of_price span {
        font-size: 12px !important;
    }
    .donation_details {
        width: 130px;
        gap: 0px;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
    }
}
.containerAnime {
    width: 100%;
    height: 100%;
  }
  .ballFav {
    width: 5px;
    height: 5px;
    margin: 5px auto;
    border-radius: 50px;
  }
  
  /* .ball:nth-child(1) {
    background: #ffffff;
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
  }
  
  .ball:nth-child(2) {
    background: #ffffff;
    -webkit-animation: left 1.1s infinite ease-in-out;
    -moz-animation: left 1.1s infinite ease-in-out;
    animation: left 1.1s infinite ease-in-out;
  }
  
  .ball:nth-child(3) {
    background: #ffffff;
    -webkit-animation: right 1.05s infinite ease-in-out;
    -moz-animation: right 1.05s infinite ease-in-out;
    animation: right 1.05s infinite ease-in-out;
  }
  
  .ball:nth-child(4) {
    background: #ffffff;
    -webkit-animation: left 1.15s infinite ease-in-out;
    -moz-animation: left 1.15s infinite ease-in-out;
    animation: left 1.15s infinite ease-in-out;
  }
  
  .ball:nth-child(5) {
    background: #ffffff;
    -webkit-animation: right 1.1s infinite ease-in-out;
    -moz-animation: right 1.1s infinite ease-in-out;
    animation: right 1.1s infinite ease-in-out;
  } */
  
  .ballFav:nth-child(1) {
    background: #ee4236;
    -webkit-animation: left 1.05s infinite ease-in-out;
    -moz-animation: left 1.05s infinite ease-in-out;
    animation: left 1.05s infinite ease-in-out;
  }
  
  .ballFav:nth-child(2) {
    background: #ee4236;
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
  }
  
  @-webkit-keyframes right {
    0% {
      -webkit-transform: translate(-15px);
    }
    50% {
      -webkit-transform: translate(15px);
    }
    100% {
      -webkit-transform: translate(-15px);
    }
  }
  
  @-webkit-keyframes left {
    0% {
      -webkit-transform: translate(15px);
    }
    50% {
      -webkit-transform: translate(-15px);
    }
    100% {
      -webkit-transform: translate(15px);
    }
  }
  
  @-moz-keyframes right {
    0% {
      -moz-transform: translate(-15px);
    }
    50% {
      -moz-transform: translate(15px);
    }
    100% {
      -moz-transform: translate(-15px);
    }
  }
  
  @-moz-keyframes left {
    0% {
      -moz-transform: translate(15px);
    }
    50% {
      -moz-transform: translate(-15px);
    }
    100% {
      -moz-transform: translate(15px);
    }
  }
  
  @keyframes right {
    0% {
      transform: translate(-15px);
    }
    50% {
      transform: translate(15px);
    }
    100% {
      transform: translate(-15px);
    }
  }
  
  @keyframes left {
    0% {
      transform: translate(15px);
    }
    50% {
      transform: translate(-15px);
    }
    100% {
      transform: translate(15px);
    }
  }
.ticker { width: 100%; }
.marquee_wraper, .marquee_wraper_arab {
    width: 190px !important;
    overflow: hidden;
    font-size: 17px;
    white-space : nowrap;
    padding: 5px;
    min-height: 44px;
}
.marquee {
    align-items: flex-start !important;
}
.normalDiv {
    width: 100% !important;
    overflow: hidden;
}
.normalDiv label, .marquee label, .marquee_eng label {
    white-space : nowrap;
    
}
.marquee_wraper .marquee label {
    margin: 0px 10px 0px 0px
}
/* .marquee_wraper .marquee:nth-child(1) label {
    margin: 0px 10px 0px 0px;
    right: 0px;
} */

.marquee_wraper_arab .marquee label {
    margin: 0px 0px 0px 10px
}
/* .marquee_wraper_arab .marquee:nth-child(2) label {
    margin: 0px 0px 0px 10px;
    right: 0px;
} */
/* .normalDiv label {
    width: 100%;
    height: 100%;
    display: inline-block;
    white-space : nowrap;
    margin: 0;
    text-align: center;
}
.marquee label:hover {
    -moz-animation: scroll-left 10s linear infinite;
    -webkit-animation: scroll-left 10s linear infinite;
    animation: scroll-left 10s linear infinite; 
    
}
.marquee_eng .subMarquee:hover {


    animation-name: scroll-right, scroll-right_back;
animation-duration: 2000ms, 2000ms;
animation-delay: 0ms, 2000ms;
animation-timing-function: linear, linear;
animation-iteration-count: infinite, infinite;


    
}
@keyframes scroll-left {
    0% {
        left: 0;
      }
      100% {
        left: -100%;
      }
}

@keyframes scroll-right {
    0% {
        left: 0;
      }
    100% {
        left: -100%;
    }
}

@keyframes scroll-right_back {
    0% {
        left: 100%;
    }
    100% {
        left: -100%;
    }
} */

@media (max-width: 1111px) {
    .marquee_wraper, .marquee_wraper_arab {
        width: 130px !important;
        font-size: 11px !important;
        min-height: 33px ;
    }
    .marquee {
        
        
        height: 100%;
        line-height: unset !important;
        
    }
    .marquee_eng {
        width: 130px !important;
    }
    .normalDiv {
        width: 130px !important;
    }
}
