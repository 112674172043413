.App {
  background-color: #fdfdfd;
}
.rcw-conversation-container .rcw-header {
  background-color: #ee4236 !important;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  text-align: unset !important;
  padding: 15px !important;
  z-index: 1000;
}
.rcw-conversation-container .rcw-header span {
  display: none;
}
.rcw-conversation-container .rcw-title {
  font-size: 20px !important;
  margin: 0;
  padding: unset !important;
  order: 1 !important;
}
.rcw-conversation-container .rcw-close-button {
  display: none;
  order: 2 !important;
}
.rcw-conversation-container .avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  vertical-align: middle;
}
.rcw-full-screen .rcw-header {
  border-radius: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}
.rcw-full-screen .rcw-title {
  padding: 0 0 15px;
}
.rcw-full-screen .rcw-close-button {
  background-color: #ee4236 !important;
  border: 0;
  display: block;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 40px;
}
.rcw-full-screen .rcw-close {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 1150px) {
  .rcw-conversation-container .rcw-header {
    border-radius: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
  }
  .rcw-conversation-container .rcw-title {
    padding: unset !important;
  }
  .rcw-conversation-container .rcw-close-button {
    background-color: #ee4236 !important;
    border: 0 !important;
    display: block !important;
    position: static !important;
    right: unset !important;
    top: unset !important;
    width: 40px !important;
  }
  .rcw-conversation-container .rcw-close {
    width: 20px !important;
    height: 20px !important;
  }
}
.rcw-message {
  margin: 10px;
  display: -ms-flexbox;
  display: flex;
  word-wrap: break-word;
}
.rcw-timestamp {
  font-size: 10px;
  margin-top: 5px;
}
.rcw-client {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
}
.rcw-client .rcw-message-text {
  background-color: #ee4236 !important;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
  color: white;
}
.rcw-client .rcw-timestamp {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.rcw-response {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
}
.rcw-response .rcw-message-text {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}
.rcw-message-text p {
  margin: 0;
}
.rcw-message-text img {
  width: 100%;
  object-fit: contain;
}
.rcw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}
.rcw-snippet {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}
.rcw-snippet-title {
  margin: 0;
}
.rcw-snippet-details {
  border-left: 2px solid #ee4236;
  margin-top: 5px;
  padding-left: 10px;
}
.rcw-link {
  font-size: 12px;
}
.quick-button {
  background: none;
  border-radius: 15px;
  border: 2px solid #ee4236;
  font-weight: 700;
  padding: 5px 10px;
  cursor: pointer;
  outline: 0;
}
.quick-button:active {
  background: #9b423c;
  color: #fff;
}
.loader {
  margin: 10px;
  display: none;
}
.loader.active {
  display: -ms-flexbox;
  display: flex;
}
.loader-container {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}
.loader-dots {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: gray;
  margin-right: 2px;
  animation: a 0.5s ease infinite alternate;
}
.loader-dots:first-child {
  animation-delay: 0.2s;
}
.loader-dots:nth-child(2) {
  animation-delay: 0.3s;
}
.loader-dots:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes a {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(5px);
  }
}
.rcw-messages-container {
  background-color: #fff;
  height: 50vh;
  max-height: 410px;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}
.rcw-full-screen .rcw-messages-container {
  height: 100%;
  max-height: none;
}
@media screen and (max-width: 1150px) {
  .rcw-messages-container {
    margin-top: 52px;
    height: 100% !important;
    max-height: none !important;
  }
}
.rcw-sender {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  background-color: #f4f7f9;
  height: 45px;
  padding: 5px;
  border-radius: 0 0 10px 10px;
}
.rcw-sender.expand {
  height: 55px;
}
.rcw-new-message {
  width: 100%;
  border: 0;
  background-color: #f4f7f9;
  height: 30px;
  padding-left: 15px;
  resize: none;
}
.rcw-new-message:focus {
  outline: none;
}
.rcw-new-message.expand {
  height: 40px;
}
.rcw-send {
  background: #f4f7f9;
  border: 0;
}
.rcw-send .rcw-send-icon {
  height: 25px;
}
@media screen and (max-width: 1150px) {
  .rcw-sender {
    border-radius: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.quick-buttons-container {
  background: #fff;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}
.quick-buttons-container .quick-buttons {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.quick-buttons-container .quick-buttons .quick-list-button {
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 1150px) {
  .quick-buttons-container {
    padding-bottom: 15px !important;
  }
}
.rcw-conversation-container {
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  margin-bottom: 15px;
  width: 42vh;
}
.rcw-conversation-container.active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.rcw-conversation-container.hidden {
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.rcw-full-screen .rcw-conversation-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 1150px) {
  .rcw-conversation-container {
    margin-bottom: 0 !important;
    height: 100vh;
    width: 100vw;
  }
}
.rcw-launcher .rcw-badge {
  position: fixed;
  top: -10px;
  right: -5px;
  background-color: red;
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
}
.rcw-launcher {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: d;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: d;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: d;
  animation-fill-mode: forwards;
  -ms-flex-item-align: end;
  align-self: flex-end;
  background-color: #ee4236 !important;
  margin-top: 0 !important;
  margin-bottom: 80px !important;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  height: 80px !important;
  cursor: pointer;
  width: 80px !important;
}
.rcw-launcher:focus {
  outline: none;
}
.rcw-open-launcher {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: c;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: c;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: c;
  animation-fill-mode: forwards;
}
.rcw-close-launcher {
  width: 20px;
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: b;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: b;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: b;
  animation-fill-mode: forwards;
}
@media screen and (max-width: 1150px) {
  .rcw-launcher {
    bottom: 0 !important;
    margin: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    position: static !important;
    right: 0 !important;
    height: auto !important;
    width: auto !important;
    border-radius: unset !important;
    width: 100%;
    background-color: transparent !important;
    box-shadow: none !important;
    display: none !important;

  }
  .rcw-launcher .rcw-badge {
    display: none;
  }
  /* .rcw-hide-sm {
    display: none !important;
  } */
}
.rcw-previewer-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
}
.rcw-previewer-container .rcw-previewer-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
}
.rcw-previewer-container .rcw-previewer-tools {
  position: fixed;
  right: 16px;
  bottom: 16px;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rcw-previewer-container .rcw-previewer-button,
.rcw-previewer-container .rcw-previewer-tools {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.rcw-previewer-container .rcw-previewer-button {
  padding: 0;
  margin: 16px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  outline: none;
  background-color: #fff;
  border: none;
}
.rcw-previewer-container .rcw-previewer-close-button {
  position: absolute;
  right: 0;
  top: 0;
}
.rcw-previewer-container .rcw-previewer-veil {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
}
@keyframes b {
  0% {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes c {
  0% {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes d {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.rcw-widget-container {
  bottom: 50px !important;
  display: -ms-flexbox;
  display: none !important;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 20px 20px 0;
  max-width: none !important;
  position: fixed;
  right: 0;
  top: unset;
  width: auto !important;
  height: auto !important;
  z-index: 100000 !important;
}
.rcw-full-screen {
  height: 100vh;
  margin: 0;
  max-width: none;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .rcw-widget-container {
    /* display: none !important; */
    height: auto !important;
    width: auto !important;
    margin: 0px !important;
    top: 0;
  }
  .pages_Wraper {
    margin-bottom: 61px;
  
  }
}
.rcw-previewer .rcw-message-img {
  cursor: pointer;
}

.swiper-container-rtl .swiper-button-prev{
  background-image: url('./assets/carousel_next.png') !important;
}

.swiper-container-rtl .swiper-button-next{
  background-image: url('./assets/carousel_prev.png') !important;
}

@media screen and (max-width:900px){
    .swiper-button-next {
      background-image: url('./assets/carousel_next.png');
      transition: all .4s ease-in-out;
  } 
    .swiper-button-prev {
      background-image: url('./assets/carousel_prev.png');
      transition: all .4s ease-in-out;
  } 
} 

.swiper-button-prev, .swiper-button-next{
  background-repeat: no-repeat;
  background-size:100% auto;
  background-position: center;
  transition: all .4s ease-in-out;
  top:39% !important;  
  height: 100px !important;
  border-radius: 30px;
}

.swiper-button-next {
  background-image: url('./assets/carousel_next.png');
}

.swiper-button-prev{
  background-image: url('./assets/carousel_prev.png');
  left: 5px !important;
}

@media screen and (min-width:900px){
  .swiper-button-next:hover, .swiper-button-prev:hover { 
    background-color: white;
    border-radius: 50px;
    height: 100px;
    cursor: pointer;
    transform: scale(1.05); 
    box-shadow: 0px 0px 10px 1px #30302f;
}
}
@media (max-width: 900px) {
  .swiper-button-prev, .swiper-button-next{
    display: none !important;
  }
}

.fa-heart{
    transition: all .2s ease-in-out;
}
@media (max-width: 540px) {
  .smallBtn_smallDevice {
      padding: 5px !important;
  }
  .titleSmall_deviceSmall {
    font-size: 17px !important;
}
.textSmall {
  font-size: 15px !important;
}
.humani_cent {
  margin-top: 8px !important;
  font-size: 11px;
}
.samApple_btn_img {
  height: 35px !important;
  width: auto !important;
}
.samApple_btn {
  height: 40px !important;
}
.human_donation_btns {
  gap: 21px !important;
}
.edit-button {
  height: 12px !important;
  width: 12px !important;
}
.socialMediaIcon {
  height: 60px !important;
  width: 60px !important;
}
.profile_button_icon {
  height: 25px !important;
  width: 25px !important;
}
.profile_btn_content {
  min-height: 40px !important;
}
.profile_btn_content label {
  min-height: unset !important;
}
.local_aid_input, .selectedCourse {
  height: auto !important;
  font-size: 12px !important;
}
.select-issue {
  padding: 6px !important;
}
.form-control {
  padding: 0px;
}
.headerForMob_sharing label {
  font-size: 14px !important;
}
.btn_for_form {
  min-height: 35px !important;
  font-size: 15px;
}
.link_account_data {
  font-size: 12px;
}
.account_name_avatar {
  height: 35px !important;
  width: 35px !important;
}
.user_items {
  margin-top: 3px !important;
}
.local_aid_btn_wraper span {
  font-size: 13px;
}
.local_aid_page {
  min-height: 500px !important;
}
.relatives_display {
  padding: 0px !important;
}
.noPadding {
  padding: 0px !important;
}
.editProfileUsername {
  font-size: 18px;
}
.editProfileCard {
  padding: 8px !important;
}
.iconForSelectCourse, .local_aid_icon {
  height: 25px !important;
  width: 25px !important;
}
.course_Item {
  padding: 10px !important;
}
#requ_details {
  font-size: 12px !important;
}
#one_event {
  padding: 10px !important;
}

}

@media (max-width: 400px) {
  .smallInput_smallDevice {
    width: 60%;
  }
  .columnFlex {
    flex-direction: column;
  }
  .columnFlex button {
    height: auto !important;
    width: auto !important;
  }
}

@media (max-width: 325px) {
  .steps_element label {
    border-bottom: none !important;
  }
  .local_aid_btn_img {
    height: 100px !important;
    width: 100px !important;
  }
  .complet_info_title {
    min-height: 80px !important;
  }
  .modal-body h5 {
    font-size: 16px !important;
  }
  .notFoundWraper {
    gap: 0px !important;
  }
}
.input:focus, textarea:focus, select:focus {
  outline: none;
}