.info_step_icon {
    height: 60px;
    width: 60px;
    object-fit: contain;
}
.info_step_icon2 {
    height: 42px;
    width: 60px;
}
.Local_aid_reg_Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    padding: 20px;
}
.steps_element {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    border: none;
    background-color: transparent;
    gap: 5px;
    z-index: 200;
}
.steps_element label {
    text-align: center;
}
.steps_element:hover label {
    color: #ee4236
}
.local_aid_steps_pagination {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.background_line {
    height: 10px;
    background-color: gray;
    position: absolute;
    top: 40%;
    z-index: 1;
    border-radius: 30px;
    left: 40px;
    right: 40px;
}
@media (max-width: 1150px) {

    .steps_element label, .fontFor_iserted {
        font-size: 10px;
    }
    .Local_aid_reg_Header {padding: 0px;}
    .info_step_icon {
        height: 40px;
        width: 40px;
    }
    .background_line {
        left: 10px;
        right: 10px;
        height: 5px;
    }
}
@media (max-width: 320px) {

    .local_aid_steps_pagination{
        flex-wrap: wrap;
    }
    .background_line {
        top: 46%;
    }
}