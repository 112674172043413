#human_case_page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}
.human_case_display {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: flex-start;
}
.human_img {
    height: 750px;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
}
.human_case_img {
    height: 100%;
    padding: 40px 0px 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}
.circle_inPage {
    fill: none;
    stroke-width: 20px;
    stroke: #ee4236;
}
.circle-bg_inPage {
    fill: none;
    stroke-width: 20px;
    stroke: grey;
}
.human_case_details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.human_case_details p {
    padding: 40px;
}

.human_donation_amount {
    width: 100%;
    gap: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.human_donation_amount input {
    border: 0px;
    padding: 20px;
    width: 60%;
    box-shadow: 2px 0px 8px #8888884f;
    outline: none;

}
svg .text {
    font-weight: bold;
}
.human_donation_btns {
    width: 100%;
    padding: 40px 0px 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}
.human_donation_btns_above {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
}
.apple_human_btn {
    width: 100%;
    transition: all .3s ease-in-out;
    border-radius: 30px;
}

.human_donate_ways {
    border-radius: 30px;
    padding: 20px;
    transition: all .3s ease-in-out;
}

@media screen and (min-width:900px){
    .human_donate_ways:hover, .apple_human_btn:hover { 
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 0px 10px 1px #616160;
  }
}

.btn4 {
    background-color: #ffffff;
    border: none;
}
.btn1 {
    background-color: #ffe1e1;
    color: #ee4236;
    border: none;
}
.btn2 {
    background-color: #ffffde;
    color: rgb(250, 229, 44);
    border: none;
}
.btn3 {
    background-color: #ddefff;
    color: rgb(42, 105, 126);
    border: none;
}
.text_for_svg {
    font-weight: bold;
    font-size: 25px;
}
.text_for_svg_down {
    font-weight: bold;
    font-size: 15px;
}
.noForMob {
    display: block;
}
.yesForMob {
    display: none;
}


/* @media (max-width: 1000px) { 
    .human_img {
        border-radius: 10px;
        height: 815px;
        object-position: top center;
    }
    .human_case_img {
        padding: 10px 0px 10px 10px !important;
    }
    .human_case_page_bar {
        display: none;
    }
} */
@media (max-width: 1000px) {
    .notShown {
        display: none !important;
    }
    .human_case_page_bar {
        display: none;
    }
    .human_case_display  {
        display: flex;
        flex-direction: column;
    }
    .human_case_img {
        padding: 0px !important;
    }
    
    .human_donation_btns {
        padding: 0px;
    }
    .apple_human_btn {
        width: 80%;
    }
    .human_case_details p {
        padding: 10px;
    }
    .noForMob {
        display: none;
    }
    .yesForMob {
        display: block;
    }
    #human_case_page {
        gap: 0px;
    }
    .human_img {
        border-radius: 0px;
        height: 300px;
        object-position: top center;
    }

}
@media (max-width: 600px) { 
.humancases_p{
    margin-top:20px  !important;
} 
}
@media (max-width: 325px) { 
    .human_donate_ways{
       border-radius: 15px;
    } 
    .yesForMob {
        display: none;
    }
}
