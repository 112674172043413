.late_payment_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    box-shadow: 2px 0px 5px #88888836;
    border-radius: 30px;
    width: 90%;
    gap: 10px;
    background-color: white;
    min-height: 142px;
}
.late_payment_body_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 7;
}
.late_payment_add {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.addBtn_img {
    height: 60px;
    width: 60px;
}
.btn_of_late_payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 99px;
}
@media (max-width: 1280px) {
    .addBtn_img {
        width: 40px;
        height: 40px;
    }
    .late_payment_item {
        padding: 5px;
        width: 100%;
    }
}