.favorites_inc_Dec {
    padding: 5px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #df1f0e10;
    border-radius: 18px;
}

.label_of_title {
    overflow: hidden;
}

.dec, .inc {
    padding: 0.5px 9px;
    border-radius: 42%;
    background-color: #fa8b806e;
    color: #ee4236;
    border: none;
    font-size: 20px;
    font-weight: bold;
}

.favorites_details {
    width: 100%;
    padding: 2px 0px 2px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: white;
    box-shadow: 1px 1px 8px #88888823;


}

.favorites_img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 190px;
    height: 150px;
    object-fit: cover;
}

.favorites_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;

}

.icon_for_favorites {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: transparent;
}

.favorites_com {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 15px;
}

@media (max-width: 1111px) {
    .dec, .inc {
        padding: 1px 5px 1px 5px !important;
        font-size: 11px !important;
    }
    .donation_img {
        width: 130px;
        height: 110px;
        border-top-right-radius: 15px !important;
        border-top-left-radius: 15px !important;
    }
   
}