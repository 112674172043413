.Buyy_now_btn {
    padding: 10px 20px 10px 20px;
    border: none;
    background-color: #ee4236;
    color: white;
    border-radius: 15px;
    width: 100%;
    transition: all .3s ease-in-out;
}
@media screen and (min-width:1150px){
    .Buyy_now_btn:hover{
        background-color: white;
        color: #ee4236 !important;
        /* transform: scale(1.1);  */
        box-shadow: 0px 0px 10px 3px #30302f;
    }
  }
.e-service-input-wraper {
    display: flex;
    flex-direction: column;
}