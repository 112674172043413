.editProfileUsername {
  width: 100%;
  text-align: center;
}
.editProfileCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 30px;
  width: 100%;
  padding: 10px;
}

.editProfileCard {
  width: 80%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 20px 1px #b5b3b357;
  padding: 14px;
  border-radius: 10px;
}
.editProfileNote {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 14px;
  border-radius: 10px;
}
.editProfileNoteCalculate {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 10px;
}
.editProfileCard input {
  width: 90%;
  border: none;
  outline: none;
}

.editProfileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editProfileSaveBtn {
  background-color: #ee4236;
  height: 50px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 80%;
}

.editProfileImgHolder {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s ease;
  background: transparent;
  &:hover {
    background: rgb(24, 24, 24);
    .editProfileImg {
      opacity: 0.3;
    }
    .uploadEditProfileDiv {
      opacity: 1;
    }
  }
}

.editProfileImg {
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.uploadEditProfileDiv {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  color: white;
}
@media (max-width: 900px) {
  .editProfileCard {
      width: 100%;
  }
  .editProfileCards {
    width: 100%;
  }
}
