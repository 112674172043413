.switch_sponsers {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.reports_dates_wraper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    align-self: center;
    gap: 20px;
    border-radius: 20px;
}
.month_year_item {
    border-radius: 30px;
    border: none;
    background-color: transparent;
    color: black;
}
.month_year_item.active {
    background-color: #ee4236;
    color:  #fff;
}
.swiper-button-prev, .swiper-button-next {
    color: transparent;
}
.swiper-button-prev::after, .swiper-button-next::after {
    font-size: unset;
}
.wraperForDates .swiper-wrapper {
    padding: 0px !important;
}
.slideForDates {
    border-right: 1px solid black;
    width: 110px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reports_dates_wraper .react-multi-carousel-list ul li {
    width: 110px !important;
    border-right: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reports_dates_wraper .react-multi-carousel-list  {
    justify-content: center;
}
.reports_dates_wraper .react-multi-carousel-list .react-multiple-carousel__arrow--right {
    right: -15px;
    color: #ee4236;
}
.reports_dates_wraper .react-multi-carousel-list .react-multiple-carousel__arrow:hover {
    background-color: transparent;
    color: #ee4236;
}
.reports_dates_wraper .react-multi-carousel-list .react-multiple-carousel__arrow {
    background-color: transparent;
    color: #ee4236;
}
.reports_dates_wraper .react-multi-carousel-list ul li:last-child {
    border-right: none;
}
.reports_dates_wraper .react-multi-carousel-list .react-multiple-carousel__arrow::before {
    color: #ee4236;
}
    .two_btns {
        display: flex;
        justify-content: center;
        grid-gap: 30px;
        gap: 30px;
        padding: 20px;
        align-items: end;
        align-self: flex-start;
    }

.send_gifts_support {
    padding: 5px 30px 5px 30px;
    border: none;
    background-color: #ee4236;
    border-radius: 10px;
    color: white;
}
.send_gifts_support:disabled, .renewPayments:disabled {
    background-color: gray;
}
.orphans_payment_details_wraper {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.payment_number_title {
    font-size: 15px;
}
.payment_number_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 72px;
}
.payment_number {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.renewPayments  {
    padding: 5px 30px 5px 30px;
    border: none;
    background-color: #ee4236;
    border-radius: 10px;
    color: white;
}
.sponsership_info_pdf_body {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sponsership_info_title {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
}
.sponsership_info_title p{
     font-weight: 600;
     color: #000;
     font-size: 20px;
     margin-bottom: 1px;
}
.pdf_downloader {
    background-color: transparent;
    border: none;
    color: gray;
}
.sponsership_info_pdf_wraper {
    width: 100%;
    /* box-shadow: 0px 0px 20px 2px #88888836; */
    border-radius: 10px;
    background-color: #F9F9F9;
    padding: 20px;
}
.data_toggle_sponsership {
    background-color: transparent;
    border: none;
    color: #ee4236;
}
.onRow {
    padding: 10px;
    width: 48% !important;
    gap: 20px;
    flex-basis: 45%;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    justify-content: space-evenly;
}
.sponsership_info_box_body {
    padding: 10px;
}
.sponsership_details_display {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
  
}
.sponcer_main{
    height: 70vh;
    padding-bottom: 50px;
}
.pdf_view{
    flex-basis: 45%;
}
.pdf_sponcer{
    display: flex;
    justify-content: flex-end;
}
.sponcer_view{
    position: relative;
    overflow: scroll;
    cursor: pointer;
    border: 8px solid #D9D9D9;
    border-radius: 19px;
    width: 100%;
    height: 100%;
}
.pdf_view .child_pdf_main{
    position: sticky;
    bottom: 0;
    width: 100%;
    cursor: pointer;
}
.pdf_images{
    width: 100%;
    height: 100%;
}
.onRow .date_pdf{
    width: 100%;
    padding-right: 24px;
}
.slider_sponcer{
    width: 100%;
}
.displayForMob {
    display: none;
}
.sponsershipTableRecord {
    text-align: center;
}
table .sponsershipTableRecord {
    width: 50%;
}
@media (max-width: 1200px) {
    .reports_dates_wraper {
        flex-direction: column !important;
    }
    .sponcer_main{
        flex-direction: column;
        height: 100% !important;
    }
    .onRow{
        width: 100% !important;
    }
    .pdf_images{
        height: 500px !important;
    }
    /* .onRow {
        grid-template-columns: 100%;
    } */
}
@media (max-width: 900px) {
    .switch_sponsers {
        padding: 5px;
    }
    
}
@media (max-width: 600px) {
    .sponsershipTableRecord {
        font-size: 11px !important;
        /* padding: 10px !important; */
    }
    .sponsership_page_navigation {
        padding: 10px 10px 0px 10px;
    }
    /* .two_btns {
        padding: 0px;
    } */
    .renewPayments, .send_gifts_support, .month_year_item {
        font-size: 11px;
    }
    .reports_dates_wraper {
        width: 100%;
    }
    .displayForMob {
        display: flex;
    }
    .displayForLaptop {
        display: none;
    }
}
@media (max-width: 500px) {
    .sponsershipTableRecord {
        font-size: 9px !important;
    }
    .textToBeSmall {
        font-size: 13px;
    }
    .two_btns {
        gap: 10px;
    }
    .sponsership_navigation_item, .sponsership_navigation_item-disabled {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .sponsership_info_pdf_body {
        flex-direction: column;
        align-items: unset;
    }
    .orphans_payment_details_wraper {
        padding: 0px;
    }
    .two_btns {
        flex-wrap: wrap;
    }
    .two_btns button {
        min-width: 153px;
    }
}