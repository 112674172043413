.sponsership_page_navigation {
    width: 100%;
    padding: 10px 25px 10px 25px;
    background-color: rgba(196, 196, 196, 0.377);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.sponsership_navigation_item, .sponsership_navigation_item-disabled {
    color: rgb(87, 86, 86);
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    border-radius: 15px;
    transition: all .3s ease-in-out;
}
.sponsership_navigation_item-disabled{
    border-bottom: 5px solid rgba(196, 196, 196, 0.377) !important;
}
#navi_spon {
    color: #ee4236;
    border-bottom: 5px solid #ee4236;
}
.selectButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    position: absolute;
    top: 8px;
    right: 25px;
    border: none;
    background-color: transparent;
}
.selectButton img {
    height: 30px;
    width: 30px;
    opacity: 0.6;
}
.selectButton label {
    font-size: 13px;
    opacity: 0.6;
}
.toolTip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    position: absolute;
    top: 8px;
    right: 100px;
    border: none;
    background-color: transparent;
}
.toolTip img {
    height: 30px;
    width: 30px;
    opacity: 0.6;
}
.toolTip label {
    font-size: 13px;
    opacity: 0.6;
}
@media screen and (min-width:900px){
    .sponsership_navigation_item-disabled:hover{
        background-color: white;
        color: #ee4236 !important;
        transform: scale(1.1) !important; 
        border-bottom: 5px solid #ee4236 !important;
    }
  }
  @media (max-width: 900px) {
    .selectButton {
        z-index: 10000;
        right: 7px;
        top: 13px;
    }
    .selectButton img {
        height: 20px;
        width: 20px;
    }
    .selectButton label {
        font-size: 11px;
    }
    .toolTip {
        z-index: 10000;
        right: 60px;
        top: 15px;
    }
    .toolTip img {
        height: 20px;
        width: 20px;
    }
    .toolTip label {
        font-size: 11px;
    }
    .forceStyle {
        width: 90% !important;
    }
}