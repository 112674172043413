#donation_display {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    white-space: nowrap;

}
.removingMargin .swiper-wrapper {
    margin-left: 0px;
}
#donations_sec {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.section_title_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section_button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 100px;
    color: #ee4236;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:1150px){
    .section_button:hover { 
      background-color: #ee4236;
      color: white;
      border-radius: 50px;
      transform: scale(1.05); 
      box-shadow: 0px 0px 3px 1px #30302f;
  }
}

#donation_display_mobile .swiper-container .swiper-wrapper {
    padding: 30px 0px 30px 0px !important;
}
@media (max-width: 1150px) {
    #donations_sec {
        padding: 10px;
        gap: 10px;
    }
    .section_button {
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .all_section_title {
        font-size: 17px !important;
        padding: 0px !important;
    }
}

.all_section_title {
    font-weight: bolder;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 0.05rem #00000087;
}