#favorites_display {
    width: 100%;
    min-height: 250px;

}
#favorites_sec {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#favorites_display .swiper-wrapper {
    padding: 10px 0px 10px 0px;
}
.section_title_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section_button {
    border: none;
    background-color: transparent;
    width: 100px;
    color: #cc0000;
    font-weight: bold;
}
@media (max-width: 1111px) {
    #favorites_sec {
        padding: 10px;
    }
}