.latepayment_page {
    padding: 30px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: minmax(min-content, max-content);

    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    min-height: 85vh;
}
@media (max-width: 750px) {
    .latepayment_page {
        padding: 20px 10px 10px 10px;
    }
} 
@media (max-width: 900px) {
    .latepayment_page {
        padding: 10px;
        /* display: flex; */
        grid-template-columns: 100%;
        /* align-items: center; */
        gap: 30px;
        margin-bottom: 180px;

    }
} 