.category_comp {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 2px 0px 5px #88888836;
    height: 294px;
    transition: all .2s ease-in-out;
}
.category_img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    flex: 2;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .category_comp:hover { 
    cursor: pointer;
    transform: scale(1.05); 
    color: #ee4236 !important;
    box-shadow: 0px 0px 10px 1px #30302f;
  }
  }

.category_label {
    flex: 1 1;
    width: 100%;
    background-color: white;
    padding: 10px;
    display: block;
    align-items: center;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    text-align: center;
    cursor: pointer;
}
@media screen and (max-width:900px){
    .category_comp:hover { 
    color: #ee4236 !important;
    box-shadow: 0px 0px 10px 1px #30302f;
  }
  }
.leftarrow-i{
    position: absolute;
    left: 20px;
    top: 30%;
}
.rightarrow-i{
    position: absolute;
    right: 20px;
    top: 30%;
}
@media (max-width: 700px) {
    .category_img {
        height: 160px !important;
        flex: unset;
        object-position: 0% 20%;
    }
    .category_comp {
        height: auto;
    }
}