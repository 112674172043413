#favorites_page .swiper-container{
    padding: 10px !important;
}

.favorites_project_comp {
    display: inline-flex;
    border-radius: 30px;
    flex-direction: column;
    align-items: center;
    transition: all .2s ease-in-out;
    box-shadow: 0px 0px 15px 2px #72727260;
}
@media screen and (min-width:1150px){
    .favorites_project_comp:hover { 
        background-color: #30302f;
        cursor: pointer;
        transform: scale(1.05); 
        color: #ee4236 !important;
        box-shadow: 0px 0px 10px 1px #30302f;
  }
}
.wraper_for_project_fav_img {
    height: 180px;
    width: 170px;
    position: relative;
}
.project_img_for_fav_page {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.btn_img_label_fav_projects {
    position: absolute;
    top: 89%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.575);
    padding: 5px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: white;
    font-weight: bold;
    text-align: center;
}
.country_for_project_favorites {
    width: 100%;
    padding: 8%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.flag_space_img {
    height: 30px;
    width: 30px;
    object-fit: cover;
}
@media (max-width: 1111px) {
    .wraper_for_project_fav_img {
        height: 140px;
        width: 140px;
        position: relative;
    }
}