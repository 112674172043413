.addPaymentCardBtn{
    cursor: pointer;
    font-weight: 600;
    transition: all .3s ease-in-out;
}

.addPaymentCardBtn2 {
    cursor: pointer;
    height: 40px;
    width: 80%;
    background-color: #000000;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}
.addPaymentCardBtn2:disabled{
    opacity: .5;
    background-color:grey;
    cursor:auto;
}
@media screen and (min-width:900px){
    .addPaymentCardBtn2:hover { 
        transform: scale(1.02);
        background-color: white;
        color: #000000;
        box-shadow: 0px 0px 10px 3px #0f0f0f;
  }
  .addPaymentCardBtn:hover { 
    transform: scale(1.02);
    background-color: white;
    color: #ee4236;
    box-shadow: 0px 0px 10px 3px #0f0f0f;
}
}

.gridWraper {
    display:grid; 
    grid-template-columns:45% 45%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%
}
.paymentBodyHere {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.rccs {
    margin: unset;
}
@media (max-width: 900px) {
    .addPaymentMethodFormHere {
        width: 80%;
    }
    .paymentBodyHere {
        width: 100%;
        margin-top: 0px;
    }
    .addPaymentCardBtnHere {
        width: 90%;
    }
    .gridWraper {
        grid-template-columns: 100%;
        margin-top: 10px;
    }
    .gridWraper div {
        margin-top: 0px !important;
    }
  }
  @media (max-width: 400px) {
    .paymentBody {
        width: 100% !important;
    }
  }
  @media (max-width: 300px) {
    .rccs__card {
        width: 100% !important;
    }
    .rccs__number {
        font-size: 15px !important;
    }
    .rccs__name {
        font-size: 15px !important;
    }
  }