.donation_page_display {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    gap: 40px;
}
#donation_page_display {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 42px;
}
@media (max-width: 1150px) {
    .donation_page_display {
        padding: 10px;
        /* grid-gap: 20px; */
    }
    .no_display {
        display: none;
    }
    #donation_page_display {
        padding: 10px 0px 0px 10px;
    }
}
@media (max-width: 350px) {
    .donation_page_display {
        padding: 0px;
        gap: 5px;
    }
    #donation_page_display {
        padding: 5px;
    }
}