.renewals-month-tile {
  border-radius: 4px;
  padding: 10px 5px;
  box-shadow: 1px 0px 8px #b9b6b64d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Choose_month_modal .modal-dialog .modal-content {
  padding: 10px;
}