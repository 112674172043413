.volunteer_portal_lobby {
    min-height: 85vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.volunteer_portal_lobby_both_sides {
    width: 100%;
    display: flex;
    gap: 30px;
}
.volunteer_portal_lobby_left_side, .volunteer_portal_lobby_right_side {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.volunteer_portal_lobby_both_sides_participants {
    width: 100%;
    display: flex;
    row-gap: 30px;
}
.volunteer_portal_lobby_left_side_participants {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media (max-width: 900px) {
    .volunteer_portal_lobby_both_sides {
        flex-direction: column;
    }
    .volunteer_portal_lobby {
        padding: 0px;
    }
    
}