.project_share_public_btn {
    padding: 20px 40px 20px 40px;
    border-radius: 10px;
    border: none;
    background-color: white;
    box-shadow: 2px 0px 8px #8888884f;
    font-size: 20px;
    transition: all .2s ease-in-out;
    overflow: hidden;
}

.project-private-public {
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.full-project-public-private-div {
  display: flex;
  width: 100%;
  padding: 40px;
}

.project-share-donation {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
}


@media screen and (min-width:900px){
    .project_share_public_btn:hover { 
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 0px 5px 2px #000000;
  }
}
@media screen and (max-width: 500px) {
    .project-private-public {
        padding: 0px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .full-project-public-private-div {

        padding: 0px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    padding: 40px;
    align-self: center;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider_share {
    width: 80%;
    height: 15px;
    background: #d1d1d1;
    outline: none;
    -webkit-transition: .2s;
    border-radius: 15px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider_share:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -1px;
    bottom: -3px;
    background-color: rgb(238, 66, 54);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider_share {
    background-color: rgb(238, 66, 54);
  }
  
  input:focus + .slider_share {
    box-shadow: 0 0 1px rgb(238, 66, 54);
  }
  
  input:checked + .slider_share:before {
    -webkit-transform: translateX(45px);
    -ms-transform: translateX(45px);
    transform: translateX(45px);
  }
  
  /* Rounded slider_shares */
  .slider_share.round {
    border-radius: 34px;
  }
  
  .slider_share.round:before {
    border-radius: 50%;
  }

  .select-issue-share.user-pay-share {
    /* padding: 40px; */
    height: 50px;
  }
  .select-issue-share {
    border: none;
    width: 70%;
    border-radius: 12px;
    box-shadow: 0 0 10px #0e0d0d61;
    -webkit-appearance: none;
}
  .donation-input-box {
    width: 100%;
    /* max-width: 500px; */
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .input-box { 
    position: relative;
    align-self: center;
    display: flex;
    width: 80%;
    justify-content: center;
  }
  .input-box-field {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .input-box-field input { 
    display: block; 
    border: 1px solid #d7d6d6; 
    background: #fff; 
    padding: 10px 10px 10px 46px; 
    width: 100%; 
  }
  
  /* .unit { 
    position: absolute; 
    display: block; 
    left: 5px; 
    top: 13px; 
    z-index: 9;
  } */

  .project_share_terms_link:link {
    color: rgb(25, 54, 198);
  }
  
  /* visited link */
  .project_share_terms_link:visited {
    color: rgb(225, 45, 45);
  }
  
  /* mouse over link */
  .project_share_terms_link:hover {
    color: rgb(255, 3, 3);
  }
  
  /* selected link */
  .project_share_terms_link:active {
    color: blue;
  }

  .terms_and_conditions_share {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 500px) {
    .terms_and_conditions_share {
      font-size: 1rem;
    }
    
  }