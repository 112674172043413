.not_found_wraper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 10px 0px 10px;
  }
  .notFound {
    height: 800px;
    width: auto
  }
  @media screen and (max-width: 1150px) {
    .notFound {
        height: auto;
        width: 90vw
    }
    .not_found_wraper {
        min-height: 600px;
  }
  }