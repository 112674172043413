.userInfoDiv {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.userInfoDiv_rightSide, .userInfoDiv_leftSide {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.userInfoDiv_leftSide {
    align-items: flex-end;
}
#EngF1 {
    font-family: english_font !important;
}
#EngF2 {
    font-family: english_font !important;
}
#EngF3 {
    font-family: english_font !important;
}
#EngF4 {
    font-family: english_font !important;
}
@media (max-width: 1114px) {
    .userInfoDiv {
        flex-direction: column;
    }
    .userInfoDiv_rightSide label, .userInfoDiv_leftSide label {
        text-align: center;
        width: 100%;
    }
}