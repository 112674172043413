.events_attents {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 50px;
  gap: 30px;
  .events_left {
    background-color: #f4f4f4;
    flex-basis: 70%;
    padding: 60px;
    border-radius: 13px;
    .title_section {
      color: #000000;
      h1 {
        line-height: 50px;
      }
      p {
        font-size: 17px;
      }
    }
    .image_section {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      img {
         width: 100%;
        object-fit: contain;
      }
    }
  }
  .events_right {
    background-color: #f4f4f4;
    flex-basis: 30%;
    padding: 40px;
    display: flex;
    border-radius: 13px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    h5{
        font-size: 25px;
    }
    .qrcode{
        p{
            padding-top: 20px;
            font-size: 23px;
            font-weight: 200;
        }
    }
    .addpin{
        input{
            background-color: #E6E6E6;
            padding: 6px 20px;
            border: none;
            border-radius: 5px;
        }
        p{
            padding-top: 20px;
            font-size: 23px;
            font-weight: 200;
            cursor: pointer;
        }
    }
    .btn_events{
        p{
            padding: 10px 30px;
            background-color: #B11818;
            border-radius: 8px;
            color: #ffff;
            cursor: pointer;
        }
    }
  }
}


@media screen and (max-width : 1300px) {
    .events_attents{
        flex-direction: column;
            padding: 25px 20px;
            .events_left{
                padding: 30px;
            }
            .events_right{
                padding: 30px;
                gap: 30px;
            }
        .image_section{
            flex-direction: column;
        }
    }
}