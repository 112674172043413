.selectCourseInputWraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px -1px 8px #88888836;
    padding: 7px;
}
.iconForSelectCourse {
    height: 35px;
    width: 35px;
    object-fit: fill;
    opacity: 0.6;
}
.selectedCourse {
    background-color: transparent;
    border: none;
    height: 40px;
    width: 100%;
}
.btn_for_submiting_courses {
    width: 90%;
    padding: 10px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    background-color: #ee4236;
    transition: all .3s ease-in-out;
}
@media screen and (min-width:1150px){
    .btn_for_submiting_courses:hover{
        background-color: white;
        color: #ee4236 !important;
        /* transform: scale(1.1);  */
        box-shadow: 0px 0px 10px 3px #30302f;
    }
  }
.courses_form {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
}
.Adding_course {
    min-height: 85vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.course_Item {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px -1px 8px #88888836;
}
.course_Item i {
    align-self: flex-end;
}
.courses_display {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
}
@media (max-width: 900px) {
    .courses_form {
        width: 100%;
    }
    .Adding_course {
        padding: 0px;
    }
}