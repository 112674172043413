.completed {
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
}

@media (max-width: 700px) {
    .completed {
        padding: 10px;
    }
}