.switch_donations {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    min-height: 700px;
}
.switch_donations_header {
    width: 75%;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-evenly;
}
.switch_donations_header_item {
    background-color: transparent;
    border: none;
    color: gray;
}
.swiper_forDonations .swiper-wrapper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
.switch_donations_header {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.switch_donation_header_real {
    box-shadow: inset 0px 0px 20px 0px #88888836;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next {
    right: 6px;
}
.swiper-button-perv {
    left: 6px;
}
.rcdn-sponsorship{
    width: 100%;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 50vh;
}
@media (max-width: 900px) {
    .swiper_forDonations {
        width: 100% !important;

    }
    .switch_donations_header_item {
        font-size: 11px;
    }
    .swiper_forDonations .swiper-wrapper {
        align-items: center;
    }
    
    .recent_donation_item {
        width: 40%;
        height: 200px;
        font-size: 11px;
        margin-bottom:20px;
      }
}

