#donation_support_page {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.apple-pay-button-inside {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: donate;
    width: 100%;
    margin-left: 4px;
    height: 30px;
}
.disable_page {
    position: relative;
    opacity: 0.1;
}
.apple-pay-button-outside {
    display: block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: donate;
    /* margin: auto; */
    margin-top: 10px;
    width: 90%;
    height: 60px;
    cursor: pointer;
}

.apple-pay-button-black {
    -apple-pay-button-style: black;
}

.apple-pay-button-white {
    -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}

.donation_support_page_display {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    align-items: center;
    justify-content: space-between;
}

.donation_support_page_left {
    width: 100%;
}

.donation_support_page_right {
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.support {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.donation_support_page_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.donation_frequ_btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    gap: 5px;
}

.btn-frequ {
    padding: 10px 40px 10px 40px;
    border-radius: 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px -1px 5px #8888880c
}

.donation_amount_btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.slider {
    -webkit-appearance: none;
    width: 80%;
    height: 15px;
    background: #d1d1d1;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 15px;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #cc0000;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #cc0000;
    cursor: pointer;
}

.donation_amount_input {
    width: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    background-color: white;
    padding: 7px;
    box-shadow: 0 0 20px #e3e3e3;
}

.donation_amount_input input {
    caret-color: blue;
    width: 100%;
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 35px;
    padding: 0px;
    line-height: 0px;
}

.donation_amount_input input:focus {
    outline: none;
}

.donation_amount_input label {
    width: 100%;
    text-align: right;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.384);
    flex: 1;
}

.donate_btn_icon_ind {
    height: 50px;
    width: 50px;
}

.btn_with_text {
    min-height: 104px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.btn-frequ {
    box-shadow: 0px 2px 5px #d3d3d3;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1114px) {
    .label_to_beSmall, .label_to_beSmall span {
        font-size: 15px !important;
    }
    .btn_with_text {
        min-width: 46px;
        min-height: 50px;
    }

    #donation_support_page {
        align-items: center;
        padding: 10px;
    }

    

    .support {
        border-radius: 0px;
    }

    .donation_support_page_right {
        padding: 15px;
    }

    

    #donation_support_page {
        gap: 0px;
    }
}
@media (max-width: 900px) {
    .shareBtnDesk {
        display: none;
    }
    .headerForDesktop {
        display: none;
    }
    .donation_support_page_display {
        display: flex; 
        flex-direction: column !important;
    }
    #donation_support_page {
        padding: 0px;
    }
    .title_to_be_very_small {
        font-size: 15px;
    }
}
@media (max-width: 535px) {
    .donate_btn_icon_ind {
        height: 40px;
        width: 40px;
    }
    .label_to_beSmall {
        font-size: 15px;
    }
}


@media (max-width: 1600px) {
    .label_to_beSmall, .label_to_beSmall span {
        font-size: 13px !important;
    }

    .dec, .inc {
        padding: 1px 5px 1px 5px !important;
        font-size: 11px !important;
    }
}

@media (max-width: 600px) {
    .btn-frequ-donate {
        padding: 20px !important;
        font-size: 14px;
        border-radius: 25px !important;
        font-weight: normal;
        width: 30% !important;
        margin: auto;
    }

    /* .dai_mob {
        font-size: 20px !important;
    } */

}
@media (max-width: 325px) {
    .label_to_beSmall {
        font-size: 10px !important;
    }
    .donate_btn_icon_ind {
        height: 20px;
        width: 20px;
    }
    .btn-frequ-donate {
        border-radius: 5px !important;
    }
}

.btn-frequ-donate{
    transition: all .2s ease-in-out;
}
@media screen and (min-width:900px){
    .btn-frequ-donate:hover { 
        background-color: #ee4236 !important;
        color: #ffffff !important;
        cursor: pointer;
        transform: scale(1.2); 
  }
}