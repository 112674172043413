.local_aid_profile_comp {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px -1px 8px #88888836;
    padding: 10px;
    min-height: 45px;
    border-radius: 10px;
}
.left_side_of_local_aid_profile_comp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.right_side_of_local_aid_profile_comp {
    display: flex;
    align-items: center;
    justify-content: center;
}
.local_aid_profile_comp_icon {
    height: 35px;
    width: 35px;
    object-fit: fill;
}
@media (max-width: 700px) {
    .local_aid_profile_comp, .left_side_of_local_aid_profile_comp {
        flex-direction: column;
    }
    .valuee {
        font-size: 11px;
    }
}