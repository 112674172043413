.sms_mode_icon {
    height: 70px;
    width: 70px;
}
.sms_mode_btn {
    background-color: transparent;
    border: none;
    border-radius: 7px;
}
.SMS_modal_wraper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.sms_amount_btn {
    background-color: #ee4236;
    color: white;
    height: 50px;
    width: 90px;
    padding: 5px 10px 5px 10px;
    border: none;
    border-radius: 7px;
}