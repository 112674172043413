.renewals {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    min-height: 85vh;
}
@media (max-width: 750px) {
    .renewals {
        padding: 20px 10px 10px 10px;
    }
} 
@media (max-width: 900px) {
    .renewals {
        margin-bottom: 225px;

    }
} 