#donation_banner {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: brightness(90%);
    padding: 10px;
}
.search_input {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search_input input {
    width: 35%;
    border: none;
    padding: 10px;
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.search_input button {
    padding: 10px;
    border: none;
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: white;
    transition: all .5s ease-in-out;
}

@media screen and (min-width:1150px){
    .search_input button:hover { 
        cursor: pointer;
      /* transform: scaleX(-1);  */
      background-color: white;
        color: #ee4236;
        transform: scale(1.05); 
        box-shadow: 0px 0px 8px 3px #919191;
  }
}

#donation_banner h1 {
    flex: 5;
    color: rgb(255, 255, 255);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 700px) {
    #donation_banner {
        height: 45vh !important;
        min-height: 45vh !important;
    }
    .search_input input {
        width: 90%;
    }
}