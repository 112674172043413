 .pdfVolunteerBtn {
    /* width: 10%; */
    background-color: #ee4236;
    border-radius: 15px;
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-self: center;
    transition: all .3s ease-in-out;
}
