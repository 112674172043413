.FooterForMob {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    grid-template-columns: auto auto auto auto auto;
    z-index: 1000000;
}
.navi_footer_mob_btn {
    background-color: white;
    border: none;
    padding: 7px;
    min-height: 55px;
}
.footer_mob_img {
    height: 25px;
    object-fit: cover;
}
.btnActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.ball {
    height: 6px;
    width: 6px;
}
@media (max-width: 900px) {
    .FooterForMob {
        display: grid;
    }
}
@media (max-width: 250px) {
    .footerCartNumber {
        width: 12px;
        height: 12px;
        /* color: red !important; */
        /* background-color: blue !important; */
        padding: 1px;
        font-size: 8px;
        right: -7px !important;
        top: 8px !important;
    }
    .footer_mob_img {
        height: 15px;
    }
    .ball {
        height: 3px;
        width: 3px;
    }
}