.country_flags_img {
    height: 100px;
    width: 100px;
}

.counteies_flags_wraper {
    padding: 10px 30px 10px 30px;
    background-color: white;
    border: none;
    box-shadow: 1px 5px 8px rgba(136, 136, 136, 0.329);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    transition: all .2s ease-in-out;
    width:100%
}

@media screen and (min-width:900px){
    .counteies_flags_wraper:hover { 
    cursor: pointer;
    transform: scale(1.05); 
    box-shadow: 0px 0px 10px 1px #000000;
  }
  }

.country_project_comp {
    display: flex;
    align-items: flex-start;
    position: relative;
    border-radius: 30px;
    border: none;
    transition: all .2s ease-in-out;
    margin-left: 20px;
}


@media screen and (min-width:900px){
    .country_project_comp:hover { 
        cursor: pointer;
        transform: scale(1.05); 
  }
}

@media (max-width: 500px) {
    .country_flags_img {
        height: 40px !important;
        width: 40px !important;
        border-radius: 5px !important;
    }
    .counteies_flags_wraper {
        padding: 10px 20px 10px 20px !important;
        max-height: 90px;
        max-width: 90px;
    }
    .counteies_flags_wraper label {
        font-size: 8px !important;

    }
}

.swiper-wrapper{
    margin-left: 15px;
}

/* .donation_favorites_title{
padding-left: 45px;
} */