/* footer {
    margin-top: 40px;
} */

#contact_us label{
    padding-left: 10px;
}
.contactusnow{
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 30px;
    width: 150px;
    padding-left: 10px;
}

@media screen and (min-width:1150px){
    .contactusnow:hover { 
        border: none;
        color:#ee4236 !important;
        cursor: pointer;
        box-shadow: 0px 0px 10px 1px #353535f1;
    }
  }


.AlignLeft{
    text-align: left;
}
.contactusnow, .cursorclick{
    cursor: pointer;
}
#footer_up {
    width: 100%;
    padding: 40px;
    background-color: #b0b0b0;
    clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}
#about_US li button { 
    background-color: transparent;
    border: none;
    padding: 0px;
}
.ERC_logo_footer {
    height: 85px;
    width: 65px;
    object-fit: fill;
}
#footer_down {
    width: 100%;
    padding: 40px 40px 0px 40px;
    background-color: #b0b0b0;
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
}
#real_footer {
    width: 100%;
    padding: 20px;
    min-height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #fdfdfd;
    display: table-cell;
}
#about_US {
    text-decoration: none;
    list-style: none;
    float: left;
    width: 33%;
}
#about_US li a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.493);
}
#about_US li a:hover {
    color: #ee4236;
    cursor: pointer;
}
#title_link {
    text-decoration: underline;
}
#name, #email {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.329);
}
#message {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.329);
    width: 100%;
    height: 150px;
}
.input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.form_inputs_inline {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: white;
    padding: 7px;
    border-radius: 5px;
}
#first_inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    box-shadow: 1px 1px 8px #88888825;

}
.messageInput {
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 7px;
    border-radius: 5px;
    box-shadow: 1px 1px 8px #88888811;

}
.contactBtn {
    width: 100%;
    border-radius: 30px;
    background-color: #ee4236;
}
footer form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}
#contact_us {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 10px;
}
#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.social_media {
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 40px;
    color: white;
}
#footer_down {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-end;
}
@media (max-width: 1600px) {
   #real_footer {
       gap: 20px;
   }

   .form_inputs_inline {
       width: 100%;
   }
   #first_inputs input {
       width: 100%;
   }
   .social_media i {
    font-size: 20px;
   }
   #footer_down label {
       font-size: 10px;
   }

   #footer_down {
       padding: 20px 0px 0px 40px;
   }
   #contact_us {
       padding: 30px;
       flex-direction: column !important;
       justify-content: center;
       align-self: flex-end;
   }
   #contact_us button {
       width: 60%;
   }
   #real_footer {
       flex-direction: column;
       justify-content: flex-start;
       gap: 0px;
   }

   #footer_up {
       padding: 20px 20px 40px 20px;
   }
}
@media (max-width: 350px) {
    #first_inputs {
        flex-direction: column;
    }
    #contact_us {
        width: 100%;
    }
    .social_media i {
        font-size: 13px;
    }
    #footer_down label {
        font-size: 9px;
    }
    #footer_down {
        padding: 20px 0px 0px 70px;
    }
}
@media (max-width: 900px) {
    footer {
        display: none !important;
    }
}