.news_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0px 0px 20px 1px #b5b3b357;
  padding: 14px;
  border-radius: 10px;
  gap: 5px;
}
.branch_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.branch_info_title {
  width: 100%;
}

.news_info {
   transition: all .2s ease-in-out;
   }

@media screen and (min-width:900px){
  .news_info:hover { 
  cursor: pointer;
  transform: scale(1.1); 
  box-shadow: 0px 0px 20px 1px #30302f;
}
}

.news_grid{
  padding: 50px;
  width: 100%;
}
@media (max-width: 1150px) {
  .news_info {
      flex-direction: column;
  }
}
@media (max-width: 900px) {
  .news_grid {
      padding: 10px;
  }
}