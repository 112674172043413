.our_branches {
  width: 10px;
  height: 100vh;
}
.branch_info_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  column-gap: 50px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
/* .nav-link {
  color: red !important;
} */

.branch_info {
  /* margin: 8px; */
  /* width: 45%; */
  /* display: inline-grid; */
  display: grid;
  grid-template-columns: 40% 50%;
  direction: ltr;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 20px 1px #b5b3b357;
  padding: 14px;
  border-radius: 10px;
  justify-content: space-between;
}
.branch_info_title {
  font-weight: bold;
  font-size: 13px;
}
.branch_info_subtitle {
  font-weight: normal;
  color: gray;
  font-size: 11px;
}
.active > .customTabLocation {
  color: red !important;
}

@media screen and (max-width: 900px) {
  .our_branches {
    width: 10px;
  }
  .branch_info_grid {
    grid-template-columns: 1fr;
    padding: 10px;
  }
}
@media screen and (max-width: 250px) {
  .branch_info {
    flex-direction: column;
    gap: 10px;
  }
}

.get_direction_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  color: #ee4236;
  box-shadow: rgb(238 66 54 / 50%) 0px 1px 4px 0px;
  border-radius: 100px;
  column-gap: 10px;
}
.get_direction_btn {
  transition: all .2s ease-in-out;
  }
@media screen and (min-width:900px){
  .get_direction_btn:hover { 
    cursor: pointer;
    transform: scale(1.1); 
    box-shadow: 0px 0px 20px 1px #8b0500f1;
  }
}
