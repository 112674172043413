#categories_page {
    padding: 50px 30px 30px 30px ;
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-evenly;
    gap: 30px;
}
.categfullwidthdiv{
    width: 100%;
    text-align: center;
    margin-top:20px;
}

@media (max-width: 900px) {
    #categories_page {
        padding: 50px 10px 10px 10px ;
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-evenly;
        gap: 30px;
    }
}