.local_aid_info_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.form_left_side, .form_right_side  {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.input_wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    box-shadow: 0px -1px 8px #88888836;
    border-radius: 10px;
    padding: 5px;
    gap: 5px;
    background-color: white;
}
.local_aid_icon {
    height: 35px;
    width: 35px;
    object-fit: fill;
}
.local_aid_input_icon_wraper {
    border: none;
    background-color: white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.local_aid_input {
    height: 35px;
    width: 100%;
    border: none;
    /* border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; */
}
.local_aid_info_form_page {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.local_aid_submit {
    width: 45%;
    background-color: #ee4236;
    border-radius: 15px;
    border: none;
    color: white;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: center;
}
.local_aid_submit:disabled {
   background-color: gray;
}
.just_the_form_without_submit {
    width: 100%;
    display: flex;
}
#spanForRelatives {
    width: 100%;
}
@media (max-width: 700px) {
    .just_the_form_without_submit {
        flex-direction: column;
    }
    .form_left_side, .form_right_side {
        height: auto;
        justify-content: flex-start;
        gap: 40px;
        padding: 20px 0px 20px 0px
    }
    .local_aid_submit {
        width: 80%;
        padding: 5px;
        margin-top: 15px;
    }
}