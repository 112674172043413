.humani_cent{
    margin-top:20px;
    text-align: center;
    color:black !important;
}
.text-bold{
    font-weight: 700 !important;
}
.humanitarian_com {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container_human {
    background-color: #f7f7f7;
    padding: 30px;
    width: 100%; 
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: flex-start;
    gap: 20px;
    /* box-shadow: -2px -2px 5px 2px #8888881a; */
    /* border-top-right-radius: 15px;
    border-top-left-radius: 15px; */
}
.standAlone {
    margin-top: -15px;
}
.circle_svg {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}
.circle {
    fill: none;
    stroke: #ee4236;
}
.circle-bg {
    fill: none;
    stroke: white;
}
.details_human {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: space-between;
}
.human_desc {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* border-radius: 10px; */
    transition: all .4s ease-in-out;
    padding: 5px;
}

@media screen and (min-width:1150px){
    .human_desc:hover { 
        cursor: pointer;
        box-shadow: 0px 0px 10px 1px #616160;
  }
}

.svg_board {
    align-self: flex-end;
    margin-right: 20px;
}
.svg_board .arrow  {
    font-family: FontAwesome;
}
.shadow_here {
    /* box-shadow: 2px 0px 5px #88888836 */
}
.btn_human {
    margin-bottom: 10px;
    padding-bottom: 5px;
}
  .human_desc p {
    font-size: 17px;
}
.for_mob {
    display: none;
}
#human{
    /* min-height: 300px !important; */
}
.svg_content{
    display: block;
    margin: auto;
    text-align: center;
    -webkit-filter: drop-shadow( 0px 0px 10px rgba(0, 0, 0, .7));
    filter: drop-shadow( 0px 0px 10px rgba(0, 0, 0, .7));
    /* Similar syntax to box-shadow */
    z-index:999;
}
.btn-donate{
    margin-top:-20px;
}
.payment_btn_wraper2 {
    display: none;
    margin-bottom: 10px;
}
.payment_btn_wraper {
    margin-top: 10px;
}

@media (max-width: 900px) {
    
    .human_desc {
        width: 100%;
    }
    .container_human {
        padding: 0px;
        gap: 0px;
    }
    .human_desc p {
        font-size: 12px;
    }
    .human_desc label:first-child {
        font-size: 12px !important;
        font-weight: 900 !important;
    }
    svg text {
        font-size: 11px;
    }
    .circle_svg, .human_desc {
        padding: 10px;
        /* box-shadow: 0 0 20px black; */
    }
    .for_mob {
        display: block;
    }
    .for_desktop {
        display: none;
    }
    .btn_human {
        background-color: transparent !important;
        background: unset;
    }
    .sukukCom{
        width: 100vw;
    }
}
@media (max-width: 1600px) {
    .btn_human {
        margin-bottom: 45px;
        padding-bottom: 10px;
    }
}

@media (max-width: 600px) {
    /* .sukuk_item_board {
        width: 75% !important;
    } */
    .btn-frequ{
        width:57px !important;
    }
    .svg_board {
        width: 100%;
    }
    .sukuk_btn_img_apple {
        width: 20px;
        height: 20px;
    }
    #projects_for_Mobile{
        min-height: 100%;
    }
}

@media (max-width: 1150px) {
    .payment_btn_wraper {
        display: none !important;
    }
    .payment_btn_wraper2 {
        width: 100% !important;
        display: flex;
    }
}

.human_case_title {
    font-weight: bolder;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 0.05rem #00000087;
}