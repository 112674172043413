.lang_logo {
  height: 20px;
  width: 20px;
}

.inDrop {
  margin-right: 5px;
}
.notfication {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.notfications_menu_desktop {
  display: block !important;
  padding: 0px;
  top: 80px;
  position: absolute;
  transform: translate3d(-120px, 35px, 0px);
  will-change: transform;
  border:2px solid #ee4236 !important;
}
.notfications_menu_mobile {
  display: block !important;
  padding: 0px;
  top: 0px;
  top: 70px;
  position: absolute;
  transform: translate3d(-278px, 25px, 0px);
  will-change: transform;
}

.selectLanguageWraper{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dropDown {
  min-width: 78px;
  border-radius: 30px;
  background-color: white;
  padding: 4px 0px 4px 0px;
  border: none;
  position: absolute;
  z-index: 10001;
  margin-top: -35px;
}


#dropDownMenuTest.dropdown-menu{
  width:78px;
  padding: 10px 0px 5px 0px !important;
  left: 10px !important;
  top:-25px !important;
}
#dropDownMenuTest .dropdown-item{
  margin: 5px 0px 0px 0px;
  width: 78px;
  height: 25px;
  font-size: 0.9rem !important;
}

.dropdown {
  width: auto;
  
}
.fa-shopping-bag,
.fa-user,
.fa-envelope,
.fa-bell,
.fa-mobile-alt,
.fa-lock,
.fa-id-card {
  color: #ee4236;
  font-size: 25px;
}

#down_header ul {
  display: flex;
  align-items: flex-start;
  list-style: none;
  flex-direction: column;

}

.btnForNav {
  cursor: pointer;
  text-decoration: none !important;
  background-color: transparent;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  padding:5px 10px 0px 10px;
  border-radius: 18px;
  /* border: 1px solid #a0a0a0; */
  transition: all .3s ease-in-out;
}
@media screen and (min-width:900px){
  .btnForNav:hover{
      background-color: #fff !important;
      color: #ee4236 !important;
      /* box-shadow: 0px 0px 3px 2px #ee4236 !important; */
    }

    button.dropdown-item:hover, a.dropdown-item:hover {
      background-color: #f5f5f5 !important;
      color: #ee4236 !important;
      /* box-shadow: 0px 0px 3px 2px #ee4236 !important; */
    }
}

#down_header {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 3;
  transition: all .3s ease-in-out;
}

#select_lang_area {
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 1000000;
}

#header {
  background-color: #30302f;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
}

.header_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.dropdown-item:active {
  background-color: white;
}
.header_btns button {
  background-color: transparent;
  border: none;
  padding-bottom: 0px;
}

.dropdown-menu {
  min-width: auto;
  z-index: 1000;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  padding: 5px;
  border: none;
  margin-top: 10px;
  margin-left: -10px;
}

#down_header .dropdown-menu.show,
#down_header .dropdown-item {
  background-color: #30302f;
  color: #fff;
}
.dropdown-item {
  cursor: pointer;
  padding:5px 10px 5px 10px;
  margin: 5px 10px 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 35px;
  /* border-bottom: 2px solid transparent; */
  /* border-top: 2px solid transparent; */
  transition: all .2s ease-in-out;
}

#main_header.main_header_logo_border{
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  /* padding:10px; */
  border-radius: 10px;
  transition: all .3s ease-in-out;
  border-bottom: 3px solid #30302f;
}

.ERC_logo {
  height: 85px;
  width: 65px;
  object-fit: fill;
  cursor: pointer;
}

@media screen and (min-width:900px){
  #main_header.main_header_logo_border:hover { 
      /* transform: scale(1.03); */
      border-bottom:3px solid rgb(209, 209, 209);
      box-shadow: 0px 0px 8px 2px #747474c9;
    }
}

@media screen and (max-width:900px){
  .dropdown-item{
    background-color: #fff !important;
    color: #000 !important;
  }
}

.ERC_logo_mob {
  display: none;
  width: 138.76px;
  height: 40px;
}

.header_icons {
  height: auto;
  width: auto;
  background-color: transparent;
  border: none;
  padding: 0px;
}
.header_icons:hover {
  background-color: transparent;
}
.bg-danger, .rounded-pill {
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: 20.04px;
  height: 20.38px;
  /* background-color: white !important;
  color: black; */
}

.notfications_menu {
  padding-top: 5px;
  height: 65vh;
  overflow-y: auto;
  z-index: 1001;
  width: 300px;
}

.notfication label {
  font-size: 15px;
}

.notfication p {
  font-size: 15px;
}

.noTime {
  display: none;
}
@media (max-width: 900px) {
  .header_btns {
    gap: 15px;
  }
}

@media (max-width: 900px) {
  .noTime {
    display: block;
  }
  #header {
    flex-direction: row-reverse !important;
    background-color: #747474;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    padding: 10px;
    align-items: center;
    box-shadow: 1px 0px 8px #747474c9;
  }
  .Toastify{
    z-index: 11000;
  }
  .Toastify__toast-container{
    z-index: 11000 !important;
  }

  .notfications_menu {
    position: fixed !important;
    top: 0px;
    transform: none !important;
    width: 300px;
    max-width: 80vw;
    height: 94vh;
    border-radius: 0px;
  }

  .selectLanguageSection {
    display: none;
  }

  #down_header ul {
    display: none;
  }

  #down_header {
    align-items: center;
    justify-content: center;
  }

  #dropdownMenuButton2 i {
    color: white;
  }

  #dropdownMenuButton2 {
    margin-right: 10px;
  }

  .ERC_logo {
    display: none;
  }

  .ERC_logo_mob {
    display: block;
    margin-right: -30px;
  }
  /* .dropdown-menu {
    margin-top: 0px;
  } */
}


.nav_header_main{
  width: 100%;
  display: inline-block;
  animation: all 3s ease-out;
  z-index: 100000;
}

.nav_header_main ul {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  margin: 0;
  padding: 0 30px;
  list-style-type: none;
  justify-content: space-evenly;
}

/* .nav_header_main  li:not(:last-child) {
    margin-right: 40px;
} */
.form_download {
  text-transform: none;

}
ul.dropdown-menu{
  display: flex;
  justify-content: flex-start;
  background-color: #30302f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.nav_header_main ul li {
    transition: background 0.5s;
  }
  .nav_header_main ul li a {
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s;
  }
  .nav_header_main ul li a.form_download {
    text-decoration: none;
    text-transform: none;
    transition: color 0.2s;
  }
  .nav_header_main  ul li ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: block;
    margin: 15px -15px;
    padding: 10px 0px 10px 0px;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: opacity 0.2s, visibility 0.2s;
    min-width: calc(160% + 20px);
  }

  .subHoveringMenu1, .subHoveringMenu2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    transition: all 1s ease-in-out;
  }
  .subHoveringMenu1 button, .subHoveringMenu2 button, .subHoveringMenu1 a {
    font-size: 14px;
    border-radius: 0px !important;
  }
  .nav_header_main ul li#services ul{
    /* width: calc(160% + 100px) !important; */
    margin-left: -15px !important;
  }
  #nav_header_main-ar  ul li ul {
  /* width: calc(175% + 10px); */
  }
  #nav_header_main-ar ul li#services ul{
    width: calc(180% + 100px) !important;
    margin-left: -15px !important;
  }
  .nav_header_main ul li ul li {
    margin: 0px 0px 0px 10px;
  }
  .nav_header_main ul li:hover ul {
    visibility: visible;
    opacity: 1;
  }
  
  
  @keyframes slide-in {
    0% {
      top: -50px;
    }
    40% {
      top: 20px;
    }
    70% {
      top: 10px;
    }
    100% {
      top: 15px;
    }
  }
