.vln_linking_input {
    width: 100%;
    border: none;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.397);
    border-radius: 10px;
    padding: 10px;
}
.vln_linking_wraper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}