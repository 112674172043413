.donate_estate {
    display: flex;
    justify-content: space-between;
    .left_side_donate {
      flex-basis: 48%;
      padding: 80px 30px 80px 30px;
      .contents_donate {
        h1 {
          font-weight: 800;
        }
      }
    }
    .right_side_donate {
      flex-basis: 48%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      width: 100%;
  
      &::after {
        content: "";
        position: absolute;
        background-color: #F8F8F8;
        -webkit-clip-path: circle(65.8% at 87% 89%);
        clip-path: circle(65.8% at 81% 58%);
        width: 100%;
        height: 100%;
      }
      .image_donate {
        z-index: 3;
        display: flex;
        justify-content: end;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  
    .child_donate_main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 15px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin-top: 30px;
      padding: 40px;
      gap: 30px;
      label {
        border: 1px solid #000;
        text-align: left;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        background-color: white;
        color: black;
      }
      input,
      label,
      textarea {
        padding: 10px 10px;
        background-color: #efefef;
        border: none;
        &::placeholder {
          color: #000;
        }
      }
      p {
        margin-bottom: 1px;
        text-align: center;
        font-size: 18px;
      }
      button {
        background-color: #b11818;
        width: 100%;
        border: none;
        padding: 10px 30px;
        font-size: 20px;
        border-radius: 10px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        align-self: center;
      }
    }
  }
  
  @media screen and (min-width: 700px) and (max-width: 1200px) {
    .donate_estate {
      flex-direction: column-reverse;
      .left_side_donate {
        width: 100%;
        flex-basis: 50% !important;
        padding: 40px 20px 40px 20px;
      }
      .right_side_donate {
        width: 100%;
        flex-basis: 50% !important;
        justify-content: flex-end;
        &::after {
          // -webkit-clip-path: circle(86.9% at 10% 13%);
          clip-path: none;
        }
        .image_donate {
          z-index: 3;
          img {
            width: 100%;
            height: 700px;
            object-fit: cover;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .donate_estate {
      flex-direction: column-reverse;
      .left_side_donate {
        width: 100%;
        flex-basis: 50% !important;
        padding: 40px 20px 40px 20px;
      }
      .right_side_donate {
        width: 100%;
        flex-basis: 50% !important;
        padding: 40px 0px 40px 20px !important;
        justify-content: flex-end;
        &::after {
          // -webkit-clip-path: circle(86.9% at 10% 13%);
          clip-path: none;
          // -webkit-clip-path:  circle(86.9% at 10% 13%);
          // clip-path: circle(86.9% at 10% 13%);
          border-bottom-right-radius: 450px;
        }
        .image_donate {
          display: flex;
          justify-content: center;
          z-index: 1;
          img {
            width: 100%;
            height: 400px;
            object-fit: cover;
          }
        }
      }
    }
  }
  