.gallery_info {
  background-color: white;
  box-shadow: 0px 0px 20px 1px #b5b3b357;
  height: 250px;
  transition: all .2s ease-in-out;
  border-radius: 5px;
  box-shadow: 0px 0px 25px 1px #616160;
  z-index: 100;
  overflow: hidden;
}
.gallery_info_grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
}
@media screen and (max-width: 1150px) {
  .gallery_info_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gallery_img{
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.gallery_img img {
  transform-origin: 55% 55%;
  transition: transform 2s ease;
  border-radius: 5px;
}

@media screen and (min-width:1150px){
  .gallery_img:hover img {
  transform: scale(1.5);
  border-radius: 5px;
}
}
