.otpModalContent {
  width: 90%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 1px 0px 8px #80808078;
  border: none;
}

.otp_modal_submit {
  width: 100%;
  background-color: #ee4236;
  border-radius: 15px;
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
}
.otp_modal_submit:disabled {
  background-color: gray;
}

@media (max-width: 700px) {
  .otp_modal_submit {
    width: 80%;
    padding: 5px;
    margin-top: 15px;
  }
}

.otpModalDialog {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_input_wraper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-shadow: 0px -1px 8px #88888836;
  border-radius: 10px;
  padding: 5px;
  gap: 5px;
  background-color: white;
}
