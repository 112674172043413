.donation_inc_Dec_bag_sukuk {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    flex: 2;
    gap: 10px;
    padding: 15px;
    height: 100%;
}
.dec, .inc {
    padding: 0.5px 9px;
    border-radius: 42%;
    background-color: #fa8b806e;
    color: #f31f0c;
    border: none;
    font-size: 20px;
    font-weight: bold;
}
.big_wraper_bag {
    box-shadow: 2px 0px 5px #88888836;
    border-radius: 30px;
}
.bag_item_com_Bag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 30px;
    gap: 10px;
    width: 100%;
    height: 120px;
}
/* .img_sukuk_onBag {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
} */
.sukuk_btn_bag {
    border: none;
}
@media (max-width: 1280px) {
    .donation_inc_Dec_bag {
        padding: 0px !important;
    }
    .img_sukuk_onBag {
        width: 95px !important;
    }
}