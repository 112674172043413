#projects_page_display {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
}
.projects_page_display{
    margin: 20px;
}
.items_projects_wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.salary_choosen_projects {
    padding: 40px 0px 40px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.salary_choosen_project_btn {
    padding: 20px 40px 20px 40px;
    border-radius: 10px;
    border: none;
    background-color: white;
    box-shadow: 2px 0px 8px #8888884f;
    font-size: 20px;
    transition: all .2s ease-in-out;
    overflow: hidden;
}

@media screen and (min-width:900px){
    .salary_choosen_project_btn:hover { 
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 0px 5px 2px #000000;
  }
}

.project_desc {
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
}
.project_desc_title {
    color: gray;
    overflow: hidden;
}
.project_desc_content {
    color: black;
    font-weight: bold;
}
#projects_carousel .carousel-indicators{
    display: none;
}

#projects_carousel .carousel-control-next,
#projects_carousel .carousel-control-prev {
    background: none !important;
    width: 2.5%;
    transition: all .4s ease-in-out;
    margin-top:10%;
    margin-bottom: 10%;
}

@media screen and (min-width:900px){
    #projects_carousel .carousel-control-next:hover, 
    #projects_carousel .carousel-control-prev:hover { 
        border-radius: 50px;
        cursor: pointer;
        transform: scale(1.05); 
        box-shadow: 0px 5px 10px 1px #616160;
  }
}

#projects_carousel .carousel-control-prev, #projects_carousel .carousel-control-prev-icon {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); */
    background-image: url("../../../../../assets/carousel_prev.png");

}
 #projects_carousel .carousel-control-next, #projects_carousel .carousel-control-next-icon {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ee4236'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); */
    background-image: url("../../../../../assets/carousel_next.png");
}


.projects_display_for_prjects_page {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 20px;
    padding: 25px !important;
}
.filter_btn {
    background-color: transparent;
    border: none;
    transition: all .3s ease-in-out;
}

.filter_icon {
    height: 25px;
    width: 25px;
}
#volume2{
    width:0;
    visibility: hidden; /* display property wasn't working with transition */
    transition: width 2s;
    float: left;
  }
  .search {
      width: 100%;
  }
.filter_wraper {
    z-index: 100000;
    position: fixed;
    bottom: 60px;
    width: 120px;

    margin-right: auto;
    margin-left: auto;
    background-color: #030303;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 30px;
    padding: 3px 10px 3px 10px ;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.363);
    align-self: center;
    transition: all .2s ease-in-out;
  }
@media screen and (min-width:900px){
    .filter_wraper:hover { 
        cursor: pointer;
        transform: scale(1.05);
        transform-style: preserve-3d;
        color: #ee4236;
        background-color: #b0b0b0;
        box-shadow: 0px 0px 10px 1px #0f0f0f;
  }
}
  
.filter_wraper_inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 15px;
    padding: 10px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.363);
}
.doneBtn {
    background-color: #ee4236;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
    min-width: 100px;
    transition: all .2s ease-in-out;
}

@media screen and (min-width:900px){
    .doneBtn:hover { 
        cursor: pointer;
        transform: scale(1.05);
        background-color: white;
        color: #ee4236;
        box-shadow: 0px 0px 10px 1px #30302f;
  }
}

.headerOFFilter {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 10px 0px 10px;
    display: none;
}
@media (max-width: 1600px) {
    .projects_display_for_prjects_page {
        grid-template-columns: auto auto auto auto  ;
        padding: 5px;
        min-height: 530px !important;
    }
    #projects_page_display {
        padding: 10px;
    }
    .salary_choosen_project_btn {
        padding: 20px 15px 20px 15px;
        font-size: 15px;
    }
}

@media screen and (min-width:900px){
    .bottom_btn{
        transition: all .3s ease-in-out !important;
        width: 70% !important;
    }
    .bottom_btn:hover, apple-pay-button-black:hover { 
        transform: scale(1.05) !important;
        box-shadow: 0px 0px 15px 1px #5a5a5a !important;
  }
}


@media (max-width: 500px) {
    .projects_display_for_prjects_page {
        grid-template-columns: auto auto auto  ;
        min-height: 550px !important;
    }
}
@media (max-width: 360px) {
    .projects_display_for_prjects_page {
        grid-template-columns: auto auto;
    }
}
@media (max-width: 1150px) {
    #donation_display_mobile {
        padding: 0px !important;
    }
    .salary_choosen_projects {
        padding: 15px 0px 15px 0px;
    }
    .project_desc {
        padding: 10px;
    }
    .check_out_payment_method_btns {
        padding: 0px !important;
    }
    .shadowForBtn {
        padding: 20px;
    }
    .filter_wraper {
        position: fixed;
        bottom: 60px;
        z-index: 100000;
        align-self: center;
        padding: 3px 10px 3px 10px ;
    }
    .filter_icon {
        height: 17px;
        width: 17px;
    }
    .filter_wraper_inner {
        background-color: #ffffff;
        border-radius: 15px;
        padding: 10px;
        box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.363);
        justify-content: center;
    }
    .headerOFFilter {
        justify-content: space-between;
        width: 100%;
    }
}