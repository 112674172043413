#sukuk_sec {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#sukuk_slider .swiper-wrapper {
    margin-bottom: 40px;
}
.sukuk_display {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 35px;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
#sukuk_slider {
    width: 100%;
    padding: 20px;
    display: none;
}
/* @media (max-width: 1300px) {
    .sukuk_display {
        grid-template-columns: 45% 45%;
        gap: 20px;
    }
} */
@media (max-width: 1400px) {
    .sukuk_display {
        grid-template-columns: 100%;
        display: none;
    }
    #sukuk_slider {
        display: block;
        padding: 0px;
    }
}
@media (max-width: 1150px) {
    #sukuk_sec {
        padding: 10px;
        gap: 10px;
    }
    #sukuk_slider .swiper-wrapper {
        margin-left: 0px;
    }
}

.all_section_title{
    font-weight: bolder;
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 0.05rem #00000087;
}