.headerForAnimate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.filter_max_min_wraper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    transition: all .2s ease-in-out;
}
@media (max-width: 1150px) {
    .filter_modal {
        padding: 10px !important;
    }
    .check_box_item .filter_choises {
        cursor: pointer;
        font-size: 15px;
    }
    .filter_max_min_wraper {
        width: 90%;
    }
    .filter_inner_wraper {
        flex-direction: column;
    }
}

input.slider{
    transition: all .2s ease-in-out;
}

@media screen and (min-width:1150px){
    input.slider:hover { 
        cursor: pointer;
        transform: scale(1.05);
        color: #ee4236;
        box-shadow: 0px 0px 5px 1px #30302f;
    }
    .filter_choises{
    cursor: pointer;
}
  }