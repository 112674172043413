.submit_requ_header {
    padding: 10px 25px 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid gray;
}
.partner_section {
    padding: 10px 25px 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid gray;
}
.submit_requ_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 10px;
    min-height: 85vh;
}
.submitRequestWraper {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
}
.left_side_of_submit_requ_form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.selectItemInForm {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.prisoner-input {
    border: none;
    width: 80%;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 0 10px #0e0d0d61;
    margin-bottom: 20px;
    -webkit-appearance: none;
}
.prisoner-input-flag {
    border: none;
    width: 80%;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 0 10px #0e0d0d61;
    margin-bottom: 20px;
    -webkit-appearance: none;
    direction: ltr;
}
#aid_type {
    width: 80%;
    border: none;
    height: 35px;
    background-color: white;
    box-shadow: 0px -1px 8px #88888836;
    border-radius: 10px;
    padding: 7px;
}
.textAreaItemInForm {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
#requ_details {
    padding: 7px;
    width: 80%;
    border: none;
    background-color: white;
    box-shadow: 0px -1px 8px #88888836;
    border-radius: 10px;
}
.right_side_of_submit_requ_form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}
.prisoner_inputs {
    width: 80%;
    border: none;
    height: 35px;
    background-color: white;
    box-shadow: 0px -1px 8px #88888836;
    border-radius: 10px;
    padding: 7px;
}
@media (max-width: 950px) {
    .submitRequestWraper {
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
    #requ_details {
        width: 100%;
    }
    #aid_type {
        width: 100%;
    }
    .prisoner_inputs {
        width: 100%;
    }
    .submit_requ_page {
        padding: 0px;
    }
}
