.pending {
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.notFoundWraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.noutFoundImg {
    opacity: 0.8;
    height: 150px;
    width: 120px;
    object-fit: cover;
}