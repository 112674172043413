#myInputID {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
 }
 #myInputIDBack {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
 }
 .uploading_input #for_upl {
    height: 250px;
    width: 297px;
    border: 1px solid #000000;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.uploading_input #for_upl_back {
    height: 250px;
    width: 297px;
    border: 1px solid #000000;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}
.upload_icon {
    height: 60px;
    width: 60px;
}
.upload_icon_back {
    height: 60px;
    width: 60px;
}
.uploading_input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-self: center;
    text-align: center;
}
#fileName {
    text-align: center;
    word-break: break-all;
}
#fileNameBack {
    text-align: center;
    word-break: break-all;
}