@media screen and (min-width:900px){
  .profile_page_sponsership:hover {
      box-shadow: 1px 0px 8px #30302f52;
  }
  .change_profile_of_user:hover{
    background-color: #ee4236;
    color: white !important;
    box-shadow: 0px 0px 2px 2px #fcfcfc;
  }
  .settings:hover{
    background-color: white;
      transform: scale(1.05) rotate(180deg);
      box-shadow: 0px 0px 5px 5px white;
  }
  .editProfileSaveBtn:hover{
    background-color: white;
    color: #ee4236;
    box-shadow: 0px 0px 2px 2px #5f5f5f;
  }
  .profile_page_sponsership:hover .user_items {
    border-bottom: 1px solid transparent;
    margin: 5px 20px 0px 20px;
  }
}
.user_items_last_child {
  border-bottom: none;
}
[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after {
  z-index: 10000000 !important;
}
.editProfileSaveBtn{
  transition: all .2s ease-in-out;
}
.profile_button_icon {
  height: 40px;
  width: 40px;
}
.user_data_all {
  width: 90%;
  display: grid;
  grid-template-columns: 25% 72%;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  /* justify-content: space-between; */
}
.profile_pic_outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.number_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.user_donations_numbers {
  margin-bottom: 10px;
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
#profile_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.user_data_numbers {
  font-size: 24px;
}
.user_data_titles {
  font-size: 17px;
  color: #868686;
  font-weight: 200;
}
.profile_page_header {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 230px;
  /* align-items: center; */
  gap: 10px;
  background: linear-gradient(to top,
      #fdfdfd 0%,
      #fdfdfd 40%,
      rgba(196, 196, 196, 0.63) 40%,
      rgba(196, 196, 196, 0.63) 100%);
  padding: 40px 20px 40px 20px;
  position: relative;
}

.profile_pic_wraper {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 2px 0px 5px #88888836;
  position: relative;
  background-position: top center;
  background-size: cover;
  z-index: 2;
}

.change_profile_of_user {
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
  background-color: white;
  color: #ee4236;
  position: absolute;
  top: 3px;
  left: 2px;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.three_btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.profile_page_sponsership {
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: 99% 1%;
  align-items: center;
  justify-content: space-between;
  transition: all .3s ease-in-out;
}
.user_items {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
  border-bottom: 1px solid #80808054;
  transition: margin 0.5s ease-in-out, border 0.5s ease-out;

}
.profile_second_section_wraper {
  width: 95%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.sponsership_items_section_wraper {
  padding: 20px 0px 20px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.sponserItem {
  height: 120px;
  width: 120px;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.sponserItem label {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.623));
}
.manyOfIt {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manyOfIt::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.recent_donations_section {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.recentDonations_display {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  min-height: 200px;
}

.recent_donation_item {
  border: none;
  border-radius: 15px;
  filter: grayscale(100%);
  height: 250px;
  width: 200px;
  gap: 30px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  color: white;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  transition: all .3s ease-in-out;
  margin: 5px;
}
@media screen and (min-width:900px){
  .recent_donation_item:hover{
      /* background-color: white; */
      /* color: #ee4236 !important; */
      transform: scale(1.1); 
      box-shadow: 0px 0px 10px 5px #30302f;
      filter: none !important;
  }
}

.sponserArrowToShow {
  display: none;
}

@media (max-width: 1300px) {
  .recentDonations_display {
    flex-wrap: wrap;
  }

  .profile_page_sponsership {
    flex-direction: column;
  }

  .sponsership_items_section_wraper {
    width: 100%;
    padding: 5px;
  }

  .sponserItem {
    height: 80px;
    width: 80px;
  }

  /* .sponserArrow {
    display: none;
  } */

  .sponserArrowToShow {
    display: inline;
  }

  .profile_btn_content {
    width: 100%;
    text-align: left;
  }


  /* .recent_donation_item {
    width: 95px;
    height: 100px;
    font-size: 11px;
  } */
}

.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  position: absolute;
  width:30px;
  height: 30px;
  top: 15px;
  right: 15px;
  transition: all .3s ease-in-out;
}

.settings_icon {
  height: 30px;
  width: 30px;
}
.profile_pic_outer label {
  text-align: center;
}
@media (max-width: 1150px) {
  .user_data_all {
    width: 100%;
  }
  .user_donations_numbers {
    margin-bottom: 20px;
  }
  .user_data_titles {
    font-size: 13px;
  }
  .user_data_numbers {
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  .hide_for_mobile_please {
    display: none;
  }
  .user_data_all {
    grid-template-columns: 30% 65%;
  }
  .profile_pic_wraper {
    height: 130px;
    width: 130px;
  }
  .profile_pic_outer label {
    font-size: 15px;
  }
}

@media (max-width: 550px) {
  .profile_second_section_wraper {
    padding: 0px;
  }
  .sponserItem {
    height: 60px;
    width: 60px;
  }
}
@media (max-width: 436px) {
  .user_data_titles {
    font-size: 11px;
  }
  .user_data_titles {
    font-size: 9px;
  }
  .user_data_numbers {
    font-size: 14px;
  }
  .profile_page_header {
    padding: 5px;
  }
  .user_data_all {
    grid-template-columns: 40% 55%;
  }
}

@media (max-width: 350px) {
  .profile_pic_wraper {
    height: 90px;
    width: 90px;
  }
  .profile_pic_outer label {
    font-size: 10px;
  }
  .user_data_all {
    bottom: 20px;
  }
  .user_data_all {
    grid-template-columns: 32% 64%;
  }
  .user_data_titles {
    font-size: 9px;
  }
  .user_data_numbers {
    font-size: 10px;
  }
}
@media (max-width: 350px) {
  .user_data_all {
    grid-template-columns: 95%;
    bottom: 0px;
    gap: 5px;
    bottom: -3px;
  }
  #profile_page {
    gap: 10px;
  }
  .user_data_titles {
    font-size: 11px;
  }
}

.account_tile_card {
  display: flex;
  margin: 10px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.account_tile_card .account_name_avatar {
  margin: 0px 10px;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #9f959591;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 
@import "react-spring-bottom-sheet/dist/style.css"; */