/* .grace_info {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 20px 1px #b5b3b357;
  padding: 14px;
  border-radius: 10px;
  height: 250px;
} */
.graceInfoPage {
  width: 100%;
  justify-content: flex-start;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .grace_info_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
  margin-top: 30px;
} */

/* @media screen and (max-width: 1150px) {
  .grace_info_grid {
    grid-template-columns: repeat(2, 1fr);
  }
} */

.form-r {
  margin-bottom: 15px;
  box-shadow: 0 0 10px #0e0d0d61;
  width: 100%;
}

.grace-form-icon {
  height: 25px;
  width: 25px;
  object-fit: fill;
}

.grace-form-icon-mobile {
  height: 25px;
  width: 15px;
  object-fit: fill;
  margin-right: 7px;
  margin-left: 3px;

}

.grace-form-icon-edu {
  height: 12px;
  width: 25px;
  object-fit: fill;
}

.grace-form-icon-branch {
  height: 20px;
  width: 13px;
  object-fit: fill;
  margin-left: 3px;
  margin-right: 5px;
}

.grace-form-icon-date {
  height: 17px;
  width: 17px;
  object-fit: fill;
  margin-left: 3px;
  margin-right: 3px;
}

.grace-form-icon-lang {
  height: 17px;
  width: 17px;
  object-fit: fill;
  margin-left: 3px;
}

.grace_request_submit {
  background-color: #ee4236;
  color: white;
  border: none;
  width: 100%;
  padding: 7px;
  border-radius: 5px;
}

.location-grace {
  width: 100%;
  border: none;
}

/* input[type="date"]:not(.has-value):before {
  color: lightgray;
  content: attr(placeholder);
} */