.partners_wraper {
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 10px;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 30px;
}
.partners_item {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border-radius: 15px;
}
.partner_img {
    width: 200px;
    height: 250px;
    border-radius: 15px;
    object-fit: contain;
    transform-origin: 55% 55%;
    transition: transform 1.5s ease;
}
@media screen and (min-width:900px){
    .partners_item:hover img{
        transform: scale(1.2);
        border-radius: 15px
    }
}
@media (max-width: 900px) {
    .partners_wraper {
        grid-template-columns: 45% 45%;
        margin-bottom: 0px;
    }
    .partner_img {
        width: 100%;
        height: auto;
    }
}