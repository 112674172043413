/* .carousel-inner {
  border-radius: 20px;
} */
.carousel-control-btns {
  width: 60px;
  height: 60px;
  background-color: white;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-carousel-dialog {
  max-width: 1212px !important;
}
.gallery-carousel-content {
  width: 1212px !important;
  background: transparent !important;
  border: none !important;
}
.gallery-item img {
  width: 100%;
  height: 80vh;
  background-color: white;
  object-fit: cover;
  border-radius: 16px;
}
