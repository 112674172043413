.payment_Modal .modal-body {
    padding: 0px;
}
.payment_Modal .modal-body .modal-header {
    background-color: transparent;
    border-bottom: 0px;
    padding: 0px 0px 10px 0px;
}
.payment_Modal .modal-content {
    background-color: transparent;
    border: none;
}
.payment_Modal .modal-header .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
.edit_payment_modal_body {
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 30px 15px 30px 15px;
    width: 100%;
    display: flex;
    align-items: center;justify-content: space-evenly;
}
.editPayment_amount_modal {
    cursor: pointer;
    height: 100px;
    width: 100px;
    background-color: #fff;
    border: 1px solid #a8a2a1;
    color: #ee4236;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    color: white;
}