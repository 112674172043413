.search-transaction-section {
    width: 100%;
    padding: 10px 25px 10px 25px;
    background-color: rgba(255, 255, 255, 0.377);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 14px;
}
.total-transaction-section {
    width: 100%;
    padding: 10px 25px 10px 25px;
    background-color: rgba(255, 255, 255, 0.377);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
}
.searchButton {
    border-radius: 10px;
    margin-top: 7px;
}
.search-input {
    background: #f1f1f1;

    width: 80%;
    border: none;
    padding: 3px 0px 3px 10px;
    border-radius: 10px;
    height: 40px;

}
.search-input::placeholder {
    font: 13px/3 sans-serif;
}
.search-form button {
    float: right;
    border: none;
}
.fa.fa-search {
    border: none;
    color: #ee4236;
}
.search-form {
    width: 100%;
    background: #f1f1f1;
    border-radius: 6px;
    margin-bottom: 10px;

}
.filterButton {
    opacity: 80%;
    border-radius: 12px;
    width: 40px;
    height: 40px;
    border: none;
}
.filter_trigger {
    margin-top: 10px;
}


/* 
.search_input {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search_input input {
    width: 35%;
    border: none;
    padding: 10px;
    border-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #e4dcdc;
}
.search_input button {
    padding: 10px;
    border: none;
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: white;
    transition: all .5s ease-in-out;
}

@media (max-width: 700px) {
    .search_input input {
        width: 90%;
    }
} */