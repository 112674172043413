.view-details:hover {
  padding: 10px;
  border: none;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: white;
  transition: all .5s ease-in-out;
}
.view-details {
  padding: 10px;
  border: none;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: white;
  font-family: 'english_font_bold';
}

.total_add_to_cart {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.latePayment_page_component {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 75% 20%;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 2px 0px 5px #88888836;
  border-radius: 30px;
  gap: 30px;

}
.added_text_of_late_payment {
  text-align: center;
}
.header_descr {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
@media (max-width: 750px) {
  .header_descr label {
    font-size: 1rem;
  }
  .total_add_to_cart label {
    font-size: 7px;
  }
  .latePayment_page_component {
    padding: 10px;
    border-radius: 15px;
    gap: 10px;
  }
  .total_add_to_cart {
    gap: 0px;
  }
}
@media (max-width: 750px) {
  .latePayment_page_component {
    gap: 30px;
  }
}
