.project-type-image {
  /* height: 80%; */
  width: 100%;
  height: 150px;
}

.country-image {
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  border: 2px solid #fff;
  height: 40px;
  width: 40px;
}

.project-share-images {
  position: relative;
  flex: 1;
}
.image-title-desc {
  display: flex;
  gap: 15px;
}
.project-share-card {
  display: flex;
  flex-direction: column;
  /* height: 400px; */
  justify-content: space-between;
  /* margin-bottom: 15px; */
  min-height: 450px;
}
.title_desc_only {
  margin-top: 10px;
  padding-right: 15px;
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 175px;
}
.project_share_com {
  align-self: center;
  width: 100%;
  background-color: white;
  border-radius: 3%;
  box-shadow: 0px 0px 10px #88888844;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
@media (max-width: 900px) {
  .project_share_com {
    width: 100%;
  }
}
.project_share_donation {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  border: 1px solid #0000001a;
  align-self: center;
  width: 90%;
  border-radius: 6px;
  justify-content: space-between;
  padding: 0px 10px;
  height: 100%;
  height: 60px;

}
.share_input {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid gray;
  border-left: none;
  border-image: initial;
  padding: 5px;
  background-color: transparent;
  width: 100%;
  opacity: unset;
}
.share_input_div {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: "english_font";
}
.inc_dec_label_proj {
  font-size: 15px;
  opacity: unset;
  font-family: "english_font";
}
.remainder_project_share {
  display: flex;
  padding: 0px 10px 10px 10px;
  align-self: center;
  gap: 10px;
  
}
.remainder_project_share_inner {
  display: flex;
  padding: 0px 10px 10px 10px;
  align-self: center;
  gap: 2px;
}
.remainder_aed_text {
  margin-bottom: 10px;
  font-size: x-small;
  /* padding-right: 4px; */
}
.project_share_bar {
  height: 40px;
  display: flex;
  flex-direction: column;
}

.bar_display_red {
  background-color: red;
}
.bar_display_grey {
  background-color: #80808059;;
}

.project_share_sam_apple_btn {
    border: none;
    background-color: transparent;
}

.share_item_board_payment {
    min-height: unset;
}

.share_item_board_payment {
    min-height: 98px;
}
.share_item_board_payment {
    width: 100%;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.share_btn_img_apple {
  width: 30px;
  height: 30px;
  margin-right: 15px;

}
.share_apple_pay_icon {
  height: 25px;
  margin-right: 15px;
}






.project_share_btn_remaining {
  border: none;
    /* padding: 10px 20px !important; */
    /* transition: all .2s ease-in-out; */
    /* padding: 10px 40px 10px 40px; */
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    /* box-shadow: 1px -1px 5px #8888880c; */
    /* box-shadow: 1px 1px 1px #d3d3d3d4; */
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(107 205 145 / 77%);
    color: white;
    font-size: 0.7rem;
    width: 80px;
}

div.div_for_share_pay button.button_for_share_pay{
  background: none !important;
  border:none;
  transition: all .2s ease-in-out;
}
.div_for_share_pay {
  border-radius: 10px;
  background: none;
  min-height: 66px;
  min-width: 46px;
  background-color: transparent;
  border: none;
  transition: all .3s ease-in-out;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 10px;
}
.div_for_share_pay:hover {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 0px 10px 1px #616160;
}

.btn_label_for_project_share {
  /* min-height: 39px; */
  text-align: center;
  /* font-size: 13px; */
}


@media (max-width: 350px) {
  .image-title-desc, .remainder_project_share {
    font-size: 0.7rem;
  }
}