#favorites_page {
    padding: 40px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
.hr {
    width: 90%;
}
.liked_donation_section {
    padding: 10px;
    width: 100%;
    align-self: flex-start;
    
}
.donation_favorites_title {
    /* width: 35%; */
    padding: 0px 0px 5px 0px;
    /* border-bottom: 1px solid gray; */
}
.liked_sukuk_section {
    width: 100%;
    padding: 20px;
}
@media (max-width: 1150px) {
    #favorites_page {
        padding: 20px;
    }
}
@media (max-width: 900px) {
    #favorites_page {
        padding: 0px;
        gap: 10px;
    }
}
@media (max-width: 600px) { 
    #favorites_page > h2{
        margin: 10px 0;
        width: 100%;
        text-align: center;
    }
}